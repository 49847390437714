import React from 'react'

// Redux
import { Provider, ReactReduxContext } from 'react-redux'
import { store, history } from '../store'

// Router
import { ConnectedRouter } from 'connected-react-router'
import { Switch, Route } from 'react-router'
import { getCurrentPath } from '../selectors/router'
import Paths, { getActivePath } from './routes/Paths'
import AsyncPages from './routes/AsyncPages'
import PrivateRouteContainer from '../containers/routes/PrivateRouteContainer'
import ErrorBoundary from './ErrorBoundary'
// Styles
//import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from '../theme'

const state = store.getState()
const currentPath = getCurrentPath(state)
const activePath = getActivePath(Paths, currentPath)

const App = () => (
  <Provider store={store} context={ReactReduxContext}>
    <ConnectedRouter history={history} context={ReactReduxContext}>
      <MuiThemeProvider theme={theme}>
      <ErrorBoundary fallback={<p>Something went wrong</p>}>
        <CssBaseline />
        <Switch>
          <Route path={Paths.MAINTENANCE.path} exact component={AsyncPages.Maintenance} />
          <Route path={Paths.NOT_ALLOWED.path} exact component={AsyncPages.NotAllowed} />
          {!activePath && <AsyncPages.NotFound />}
          <PrivateRouteContainer />
        </Switch>
        </ErrorBoundary>
      </MuiThemeProvider>
    </ConnectedRouter>
  </Provider>
)

export default App
