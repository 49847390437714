import axios from 'axios'
import { LOAD_FILTER } from '../constants/action-types/filter'
import * as types from '../constants/action-types/view'

const initialState = () => ({})

const loadView = (state, { payload: { viewId, temp } }) => {
  return {
    ...state,
    [viewId]: {
      ...state[viewId],
      temp,
      error: null,
      loaded: null,
      timings: null,
      loading: true,
      selected: true,
      source: axios.CancelToken.source(),
      injected_variables: null
    }
  }
}

const loadViewSuccess = (state, { payload }) => ({
  ...state,
  [payload.id]: {
    ...state[payload.id],
    data: payload.data,
    viewType: payload.view_type,
    name: payload.name,
    layout: {
      ...payload.layout,
      font: {
        family: 'proxima-nova, open-sans',
        size: '1rem',
        color: '#717171'
      }
    },
    timings: payload.timings,
    subviews: payload.sub_views,
    subviewsClickAt: payload.sub_views_click_at,
    loading: false,
    loaded: true,
    error: null,
    filters: payload.filters || [],
    injected_variables: payload.injected_variables
  }
})

const loadViewFail = (state, { payload }) => ({
  ...state,
  [payload.id]: {
    ...state[payload.id],
    error: payload.error,
    loading: false
  }
})

const loadViewCanceled = (state, { payload }) => ({
  ...state,
  [payload]: {
    ...state[payload],
    loading: false,
    source: null
  }
})

const selectSubView = (state, { viewId, subViewId }) => ({
  ...state,
  [viewId]: {
    ...state[viewId],
    selectedSubViewKey: `${subViewId}`
  }
})

const unselectSubView = (state, { payload }) => ({
  ...state,
  [payload]: {
    ...state[payload],
    selectedSubViewKey: null
  }
})

const removeView = (state, { payload }) => {
  const { [payload]: removedView, ...newState } = state
  return newState
}

const preloadViews = (state) => {
  return Object.entries(state).reduce((views, [viewId, view]) => {
    views[viewId] = { ...view, loading: true }
    return views
  }, {})
}

const view = (state = initialState(), action) => {
  switch (action.type) {
    case types.LOAD_VIEW:
      return loadView(state, action)
    case types.LOAD_VIEW_SUCCESS:
      return loadViewSuccess(state, action)
    case types.LOAD_VIEW_FAIL:
      return loadViewFail(state, action)
    case types.LOAD_VIEW_CANCELED:
      return loadViewCanceled(state, action)
    case types.SELECT_SUB_VIEW:
      return selectSubView(state, action.payload)
    case types.UNSELECT_SUB_VIEW:
      return unselectSubView(state, action)
    case types.REMOVE_VIEW:
      return removeView(state, action)
    case LOAD_FILTER:
      return preloadViews(state)
    default:
      return state
  }
}

export default view
