import * as types from '../constants/action-types/standardized-values-mapping'

function initialState () {
  return {
    loading: false,
    saving: false,
    editorOpen: false,
    standardizedValueMapping: null,
    selectedStandardizedValue: null,
    changedStandardizedValue: [],
    isStatus: 0,
    error: null
  }
}

function getStandardizedValueMapping (state) {
  return {
    ...state,
    loading: true
  }
}

function getStandardizedValueMappingSuccess (state, { payload }) {
  const isUpdated = payload && payload.status && payload.status['Updated']
  const isPending = payload && payload.status && payload.status['Pending']
  const isApplied = payload && payload.status && payload.status['Applied']
  return {
    ...state,
    standardizedValueMapping: payload.mapper,
    loading: false,
    isStatus: isUpdated === true ? 1 : isPending === true ? 2 : isApplied === true ? 3 : 0,
    error: null
  }
}

function getStandardizedValueMappingFail (state, { payload }) {
  return {
    ...state,
    loading: false,
    error: payload
  }
}

function saveStandardizedValueMapping (state) {
  return {
    ...state,
    saving: true,
    loading: true
  }
}

function saveStandardizedValueMappingSuccess (state, { payload }) {
  return {
    ...state,
    editorOpen: false,
    changedStandardizedValue: [],
    selectedStandardizedValue: null,
    loading: false,
    saving: false
  }
}

function saveStandardizedValueMappingFail (state, { payload }) {
  return {
    ...state,
    saving: false,
    editorOpen: true,
    loading: false,
    changedStandardizedValue: [],
    error: payload
  }
}

function openStandardizedValueEditor (state, { payload }) {
  const optionArr = [...payload.options]
  optionArr.map((el, index) => {
    el['displayValue'] = el.source_value
    el['value'] = el.source_value
    el['disabled'] = false
    el['type'] = 'unmapped values'
    return true
  })
  const result = []
  const selectedValues = []
  const standardizedValueArr = [...payload.standardized_values]
  standardizedValueArr.map((item) => {
    const list = []
    optionArr.map((optionItem) => {
      if (optionItem.standardized_value === item) {
        list.push(optionItem)
        selectedValues.push({ standardized_value: optionItem.standardized_value, source_value: optionItem.value })
        optionItem.disabled = true
        optionItem.type = 'mapped values'
      }
      return true
    })
    if (list.length) {
      result.push({ name: item, list, options: optionArr })
    } else {
      list.push({
        enum: '',
        source_value: '',
        standardized_value: '',
        status: '',
        displayValue: '',
        value: '',
        disabled: false
      })
      result.push({ name: item, list, options: optionArr })
    }
    return true
  })
  return {
    ...state,
    editorOpen: true,
    selectedStandardizedValue: {
      group_id: payload.group_id,
      group_name: payload.group_name,
      mapping: result,
      selectedValues
    }
  }
}

function closeStandardizedValueEditor (state) {
  return {
    ...state,
    editorOpen: false,
    selectedStandardizedValue: null,
    changedStandardizedValue: []
  }
}

function changeStandardizedValueMapping (state, { payload: { el, itemIndex, listIndex, listItem, name } }) {
  const selectedValue = state && state.selectedStandardizedValue && state.selectedStandardizedValue.mapping
  selectedValue && selectedValue[itemIndex].options.map((optionItem) => {
    if (selectedValue[itemIndex].list[listIndex].source_value === optionItem.value) {
      optionItem.disabled = false
      optionItem.type = 'unmapped values'
    }
    if (optionItem.value === el.value) {
      optionItem.disabled = true
      optionItem.type = 'mapped values'
    }
    return true
  })
  const fieldData = [...state.changedStandardizedValue]
  if (selectedValue) {
    let item = selectedValue[itemIndex].list[listIndex]
    if (item.value !== '') {
      fieldData.push({
        group_id: listItem && listItem.group_id,
        status: 'Updated',
        enum: item.enum,
        standardized_value: null,
        source_value: item.value,
        mapping_ids: item.mapping_ids,
        itemIndex,
        listIndex
      })
    }
    selectedValue[itemIndex].list[listIndex].source_value = el.value
  }
  const data = {
    group_id: listItem && listItem.group_id,
    status: 'Updated',
    enum: el && el.enum,
    standardized_value: name,
    source_value: el && el.value,
    mapping_ids: el && el.mapping_ids,
    itemIndex,
    listIndex
  }
  if (fieldData.length) {
    const index = fieldData.findIndex((ol) => ol.listIndex === listIndex && ol.itemIndex === itemIndex && ol.standardized_value !== null)
    if (index === -1) {
      fieldData.push(data)
    } else {
      fieldData[index] = data
    }
  } else {
    fieldData.push(data)
  }
  return {
    ...state,
    selectedStandardizedValue: {
      ...state.selectedStandardizedValue,
      mapping: selectedValue && [...selectedValue]
    },
    changedStandardizedValue: fieldData && [...fieldData]
  }
}

function removeStandardizedValue (state, { payload: { itemIndex, listIndex, listItem, item } }) {
  const selectedValue = state && state.selectedStandardizedValue && state.selectedStandardizedValue.mapping
  const changedData = [...state.changedStandardizedValue]
  selectedValue && selectedValue[itemIndex].options.map((el) => {
    if (el.value === listItem.source_value) {
      el.disabled = false
      el.type = 'unmapped values'
    }
    return true
  })
  if (listItem && listItem.source_value !== '') {
    if (listItem.source_value !== null) {
      let data = {
        group_id: item && item.group_id,
        status: 'Updated',
        enum: listItem.enum,
        standardized_value: null,
        source_value: listItem.source_value,
        mapping_ids: listItem.mapping_ids
      }
      if (changedData.length) {
        const index = changedData.findIndex((ol) => ol.source_value === listItem.source_value)
        if (listItem.value !== '') {
          if (index === -1) {
            changedData.push(data)
          } else {
            changedData[index] = data
          }
        } else {
          changedData.splice(index, 1)
        }
      } else {
        changedData.push(data)
      }
    }
  }
  if (selectedValue) {
    if (selectedValue[itemIndex].list.length === 1) {
      selectedValue[itemIndex].list[listIndex].source_value = ''
    } else {
      selectedValue[itemIndex].list.splice(listIndex, 1)
    }
  }
  return {
    ...state,
    selectedStandardizedValue: {
      ...state.selectedStandardizedValue,
      mapping: selectedValue && [...selectedValue]
    },
    changedStandardizedValue: changedData && [...changedData]
  }
}

function addStandardizedValue (state, { payload }) {
  const selectedValue = state && state.selectedStandardizedValue && state.selectedStandardizedValue.mapping
  selectedValue && selectedValue[payload].list.push({
    enum: '',
    source_value: null,
    standardized_value: '',
    status: '',
    displayValue: '',
    value: '',
    disabled: false
  })
  return {
    ...state,
    selectedStandardizedValue: {
      ...state.selectedStandardizedValue,
      mapping: selectedValue && [...selectedValue]
    }
  }
}

function isChangedValueMapping (state) {
  const mappedValue = state && state.selectedStandardizedValue && state.selectedStandardizedValue.selectedValues
  const changedData = [...state.changedStandardizedValue]
  let arr = changedData.slice()
  mappedValue && mappedValue.map((mappedItem) => {
    arr.map((fieldItem) => {
      if (mappedItem.source_value === fieldItem.source_value && mappedItem.standardized_value === fieldItem.standardized_value) {
        arr = arr.filter((jl) => jl.source_value !== mappedItem.source_value)
      }
      return true
    })
    return true
  })
  return {
    ...state,
    changedStandardizedValue: arr
  }
}

export default function mapping (state = initialState(), action) {
  switch (action.type) {
    case types.GET_STANDARDIZED_VALUE_MAPPING:
      return getStandardizedValueMapping(state)
    case types.GET_STANDARDIZED_VALUE_MAPPING_SUCCESS:
      return getStandardizedValueMappingSuccess(state, action)
    case types.GET_STANDARDIZED_VALUE_MAPPING_FAIL:
      return getStandardizedValueMappingFail(state, action)
    case types.SAVE_STANDARDIZED_VALUE_MAPPING:
      return saveStandardizedValueMapping(state)
    case types.SAVE_STANDARDIZED_VALUE_MAPPING_SUCCESS:
      return saveStandardizedValueMappingSuccess(state, action)
    case types.SAVE_STANDARDIZED_VALUE_MAPPING_FAIL:
      return saveStandardizedValueMappingFail(state, action)
    case types.OPEN_STANDARDIZED_VALUE_EDITOR:
      return openStandardizedValueEditor(state, action)
    case types.CLOSE_STANDARDIZED_VALUE_EDITOR:
      return closeStandardizedValueEditor(state)
    case types.CHANGE_STANDARDIZED_VALUE_MAPPING:
      return changeStandardizedValueMapping(state, action)
    case types.REMOVE_STANDARDIZED_VALUE:
      return removeStandardizedValue(state, action)
    case types.ADD_STANDARDIZED_VALUE:
      return addStandardizedValue(state, action)
    case types.IS_CHANGED_VALUE:
      return isChangedValueMapping(state)
    default:
      return state
  }
}
