import * as types from '../constants/action-types/me'
import { IMPERSONATE_ORGANIZATION_SUCCESS } from '../constants/action-types/organization'
import { SAVE_MAPPING_SUCCESS } from '../constants/action-types/mapping'
import { SAVE_ROLE_SUCCESS, DELETE_ROLE_SUCCESS } from '../constants/action-types/role'
import { Role, UserType } from '../constants'

function initialState () {
  return {
    userId: '',
    firstName: '',
    lastName: '',
    email: '',
    organizationUUID: '',
    organizationName: '',
    viewTypes: [],
    mappers: [],
    roles: [],
    userType: null,
    isOrgAdmin: false,
    loading: false,
    inMaintenance: false,
    error: null
  }
}

function loadMe (state) {
  return {
    ...state,
    loading: true
  }
}

function loadMeSuccess (state, payload) {
  return {
    ...state,
    loading: false,
    inMaintenance: payload.in_maintenance,
    userId: payload.id,
    firstName: payload.first_name,
    lastName: payload.last_name,
    email: payload.email,
    organizationUUID: payload.organization.uuid,
    organizationName: payload.organization.name,
    viewTypes: payload.view_types,
    mappers: payload.mappers || [],
    roles: payload.roles || [],
    isOrgAdmin: payload.is_org_admin,
    userType: payload.can_impersonate
      ? UserType.TARGETX_ADMIN
      : payload.user_roles.includes(Role.ADMIN)
        ? UserType.SCHOOL_ADMIN
        : UserType.NORMAL_USER
  }
}

function impersonateOrganizationSuccess (state, payload) {
  return {
    ...state,
    mappers: payload.mappers || [],
    roles: payload.roles || []
  }
}

function loadMeFail (state, { error }) {
  return {
    ...state,
    loading: false,
    error
  }
}

function saveMappingSuccess (state, { mapperUUID, complete }) {
  return {
    ...state,
    mappers: state.mappers.map(mapper => {
      if (mapper.uuid !== mapperUUID) return mapper
      return { ...mapper, complete }
    })
  }
}

function saveRoleSuccess (state, { role, isNew }) {
  let roles = isNew ? (
    [...state.roles, role]
  ) : (
    state.roles.map(r => {
      if (r.uuid !== role.uuid) return r
      return { ...role }
    })
  )

  if (role.default) {
    roles.forEach(r => {
      r.default = r.uuid === role.uuid
    })
  }

  roles = [
    roles.find(role => role.name === Role.ADMIN),
    ...roles
      .filter(role => role.name !== Role.ADMIN)
      .sort((a, b) => String(a.name).localeCompare(String(b.name)))
  ]

  return {
    ...state,
    roles
  }
}

function deleteRoleSuccess (state, { role, newDefaultRole }) {
  return {
    ...state,
    roles: state.roles
      .filter(r => r.uuid !== role.uuid)
      .map(r => {
        if (!newDefaultRole || r.uuid !== newDefaultRole.uuid) return r
        return { ...r, default: true }
      })
  }
}

export default function me (state = initialState(), action) {
  switch (action.type) {
    case types.LOAD_ME:
      return loadMe(state)
    case types.LOAD_ME_SUCCESS:
      return loadMeSuccess(state, action.payload)
    case IMPERSONATE_ORGANIZATION_SUCCESS:
      return impersonateOrganizationSuccess(state, action.payload)
    case types.LOAD_ME_FAIL:
      return loadMeFail(state, action.payload)
    case SAVE_MAPPING_SUCCESS:
      return saveMappingSuccess(state, action.payload)
    case SAVE_ROLE_SUCCESS:
      return saveRoleSuccess(state, action.payload)
    case DELETE_ROLE_SUCCESS:
      return deleteRoleSuccess(state, action.payload)
    default:
      return state
  }
}
