import * as types from '../constants/action-types/category-dashboard'
import { groupBy } from 'lodash'

const initialState = () => ({
  all: {},
  categories: [],
  savingDashboard: {},
  loading: false,
  error: ''
})

const loadCategoryDashboards = (state) => ({
  ...state,
  loading: true
})

const loadCategoryDashboardsSuccess = (state, { payload }) => ({
  ...state,
  all: groupBy(payload.dashboards, 'category'),
  categories: payload.categories.sort((a, b) => a.order - b.order),
  loading: false
})

const loadCategoryDashboardsFail = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: false
})

const saveCategoryDashboards = (state, { payload }) => ({
  ...state,
  savingDashboard: payload.reduce(
    (dashboards, dashboard) => ({
      ...dashboards,
      [dashboard.id]: true
    }),
    {}
  ),
  loading: true
})

const saveCategoryDashboardsSuccess = (state, { payload }) => ({
  ...state,
  all: {
    ...state.all,
    [payload.dashboards[0].category]: payload.dashboards.sort((a, b) => a.order - b.order)
  },
  savingDashboard: {}, // Assuming no parallel calls
  loading: false
})

const saveCategoryDashboardsFail = (state, { payload }) => ({
  ...state,
  savingDashboard: {}, // Assuming no parallel calls
  error: payload,
  loading: false
})

const categoryDashboard = (state = initialState(), action) => {
  switch (action.type) {
    case types.LOAD_CATEGORY_DASHBOARDS:
      return loadCategoryDashboards(state, action)
    case types.LOAD_CATEGORY_DASHBOARDS_SUCCESS:
      return loadCategoryDashboardsSuccess(state, action)
    case types.LOAD_CATEGORY_DASHBOARDS_FAIL:
      return loadCategoryDashboardsFail(state, action)
    case types.SAVE_CATEGORY_DASHBOARDS:
      return saveCategoryDashboards(state, action)
    case types.SAVE_CATEGORY_DASHBOARDS_SUCCESS:
      return saveCategoryDashboardsSuccess(state, action)
    case types.SAVE_CATEGORY_DASHBOARDS_FAIL:
      return saveCategoryDashboardsFail(state, action)
    default:
      return state
  }
}

export default categoryDashboard
