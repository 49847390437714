/*export default {
  root: {
    '&:before': {
      display: 'none'
    }
  },
  expanded: {
    margin: 0
  }
}*/


export default {
  root: {
    '&$expanded': {
      margin: 0
    }
  }
}