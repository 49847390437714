import * as types from '../constants/action-types/dashboard'
import { LOAD_ME_SUCCESS } from '../constants/action-types/me'
import { IMPERSONATE_ORGANIZATION_SUCCESS } from '../constants/action-types/organization'
import { APPLY_FILTERS, CLEAR_FILTERS } from '../constants/action-types/filter'
import { GO_TO } from '../constants/action-types/path'
import { persistDateRange, retrieveDateRange } from '../handlers/localStorage'
import moment from 'moment'

const defaultDateRange = {
  startDate: moment().subtract(30, 'days').toDate(),
  endDate: moment().toDate()
}

const initialState = () => ({
  categories: [],
  dashboards: [],
  selectedCategory: null,
  selectedDashboardId: null,
  openTableDialogId: null,
  headerExpanded: false,
  drillDownOpen: false,
  dateRangePickerOpen: false,
  dateRange: defaultDateRange,
  changedDateRange: defaultDateRange
})

const loadMeSuccess = (state, { payload }) => ({
  ...state,
  categories: payload.categories || [],
  dashboards: payload.dashboards
    ? payload.dashboards.map(dashboard => ({
      ...dashboard,
      id: String(dashboard.id)
    }))
    : []
})

const selectDashboard = (state, { payload: dashboardId }) => {
  const dashboard = state.dashboards.find(dashboard => dashboard.id === dashboardId)
  const selectedCategory = dashboard ? dashboard.category : null

  return {
    ...state,
    selectedCategory,
    selectedDashboardId: dashboardId
  }
}

const unselectDashboard = (state) => {
  return {
    ...state,
    selectedCategory: null,
    selectedDashboardId: null,
    openTableDialogId: null
  }
}

const expandDashboardHeader = (state) => {
  return {
    ...state,
    headerExpanded: true
  }
}

const collapseDashboardHeader = (state) => {
  return {
    ...state,
    headerExpanded: false
  }
}

const openDrillDown = (state) => {
  return {
    ...state,
    drillDownOpen: true
  }
}

const closeDrillDown = (state) => {
  return {
    ...state,
    drillDownOpen: false
  }
}

const openTableDialog = (state, { payload: viewId }) => {
  return {
    ...state,
    openTableDialogId: viewId
  }
}

const closeTableDialog = (state) => {
  return {
    ...state,
    openTableDialogId: null
  }
}

const openDateRangePicker = (state) => {
  return {
    ...state,
    dateRangePickerOpen: true,
    changedDateRange: state.dateRange
  }
}

const closeDateRangePicker = (state) => {
  return {
    ...state,
    dateRangePickerOpen: false
  }
}

const changeDateRange = (state, { payload: dateRange }) => {
  return {
    ...state,
    changedDateRange: dateRange
  }
}

const applyDateRange = (state, { payload: dateRange }) => {
  return {
    ...state,
    dateRange,
    dateRangePickerOpen: false
  }
}

const dashboard = (state = retrieveDateRange(initialState()), action) => {
  switch (action.type) {
    case LOAD_ME_SUCCESS:
    case IMPERSONATE_ORGANIZATION_SUCCESS:
      return loadMeSuccess(state, action)
    case types.SELECT_DASHBOARD:
      return selectDashboard(state, action)
    case GO_TO:
      return unselectDashboard(state)
    case APPLY_FILTERS:
    case CLEAR_FILTERS:
      return collapseDashboardHeader(state)
    case types.EXPAND_DASHBOARD_HEADER:
      return expandDashboardHeader(state)
    case types.COLLAPSE_DASHBOARD_HEADER:
      return collapseDashboardHeader(state)
    case types.OPEN_DRILL_DOWN:
      return openDrillDown(state)
    case types.CLOSE_DRILL_DOWN:
      return closeDrillDown(state)
    case types.OPEN_TABLE_DIALOG:
      return openTableDialog(state, action)
    case types.CLOSE_TABLE_DIALOG:
      return closeTableDialog(state)
    case types.OPEN_DATE_RANGE_PICKER:
      return openDateRangePicker(state)
    case types.CLOSE_DATE_RANGE_PICKER:
      return closeDateRangePicker(state)
    case types.CHANGE_DATE_RANGE:
      return changeDateRange(state, action)
    case types.APPLY_DATE_RANGE:
      return persistDateRange(applyDateRange(state, action))
    default:
      return state
  }
}

export default dashboard
