export const LOAD_ORGANIZATIONS = 'LOAD_ORGANIZATIONS'
export const LOAD_ORGANIZATIONS_SUCCESS = 'LOAD_ORGANIZATIONS_SUCCESS'
export const LOAD_ORGANIZATIONS_FAIL = 'LOAD_ORGANIZATIONS_FAIL'
export const LOAD_ORGANIZATIONS_CANCELED = 'LOAD_ORGANIZATIONS_CANCELED'
export const IMPERSONATE_ORGANIZATION = 'IMPERSONATE_ORGANIZATION'
export const IMPERSONATE_ORGANIZATION_SUCCESS = 'IMPERSONATE_ORGANIZATION_SUCCESS'
export const IMPERSONATE_ORGANIZATION_FAIL = 'IMPERSONATE_ORGANIZATION_FAIL'
export const STOP_IMPERSONATING = 'STOP_IMPERSONATING'
export const CANCEL_IMPERSONATION = 'CANCEL_IMPERSONATION'
export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION'
export const SELECT_ORGANIZATION_TO_EDIT = 'SELECT_ORGANIZATION_TO_EDIT'
export const UNSELECT_ORGANIZATION_TO_EDIT = 'UNSELECT_ORGANIZATION_TO_EDIT'
export const EDIT_ORGANIZATION = 'EDIT_ORGANIZATION'
export const SAVE_ORGANIZATION = 'SAVE_ORGANIZATION'
export const SAVE_ORGANIZATION_SUCCESS = 'SAVE_ORGANIZATION_SUCCESS'
export const SAVE_ORGANIZATION_FAIL = 'SAVE_ORGANIZATION_FAIL'
export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION'
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS'
export const DELETE_ORGANIZATION_FAIL = 'DELETE_ORGANIZATION_FAIL'
export const CLEAR_ORGANIZATION_CACHE = 'CLEAR_ORGANIZATION_CACHE'
export const CLEAR_ORGANIZATION_CACHE_SUCCESS = 'CLEAR_ORGANIZATION_CACHE_SUCCESS'
export const CLEAR_ORGANIZATION_CACHE_FAIL = 'CLEAR_ORGANIZATION_CACHE_FAIL'
export const OPEN_ORGANIZATION_EDITOR = 'OPEN_ORGANIZATION_EDITOR'
export const CLOSE_ORGANIZATION_EDITOR = 'CLOSE_ORGANIZATION_EDITOR'
export const LOAD_DATASOURCES = 'LOAD_DATASOURCES'
export const LOAD_DATASOURCES_SUCCESS = 'LOAD_DATASOURCES_SUCCESS'
export const LOAD_DATASOURCES_FAIL = 'LOAD_DATASOURCES_FAIL'
