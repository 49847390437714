import { getSelectedOrganizationName } from '../../selectors/organization'
import { getSelectedViewName } from '../../selectors/view-editor'
import { getSelectedFilterName } from '../../selectors/filter-editor'
import { getSelectedCategoryName } from '../../selectors/school-admin-dashboard'
import { getSelectedMapperName } from '../../selectors/mapping'
import { getSelectedRoleName } from '../../selectors/role'

export default {
  ORGANIZATION: ['Organizations', getSelectedOrganizationName],
  VIEW: ['View Editor', getSelectedViewName],
  VIEW_PREVIEW: ['View Editor', getSelectedViewName, 'Preview'],
  FILTER: ['Filter Editor', getSelectedFilterName],
  SCHOOL_ADMIN_DASHBOARDS: [getSelectedCategoryName],
  MAPPING: [getSelectedMapperName],
  ROLE: [getSelectedRoleName]
}
