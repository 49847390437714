import * as types from '../constants/action-types/filter'

export function loadFilters () {
  return { type: types.LOAD_FILTERS }
}

export function loadFiltersSuccess (payload) {
  return { type: types.LOAD_FILTERS_SUCCESS, payload }
}

export function loadFiltersFail (payload) {
  return { type: types.LOAD_FILTERS_FAIL, payload }
}

export function loadFilter (payload) {
  return { type: types.LOAD_FILTER, payload }
}

export function loadFilterSuccess (payload) {
  return { type: types.LOAD_FILTER_SUCCESS, payload }
}

export function loadFilterFail (payload) {
  return { type: types.LOAD_FILTER_FAIL, payload }
}

export function showFiltersPanel () {
  return { type: types.SHOW_FILTERS_PANEL }
}

export function hideFiltersPanel () {
  return { type: types.HIDE_FILTERS_PANEL }
}

export function selectFilter (payload) {
  return { type: types.SELECT_FILTER, payload }
}

export function addSelectedValue (payload) {
  return { type: types.ADD_SELECTED_VALUE, payload }
}

export function removeSelectedValue (payload) {
  return { type: types.REMOVE_SELECTED_VALUE, payload }
}

export function selectFilterGroup (payload) {
  return { type: types.SELECT_FILTER_GROUP, payload }
}

export function deselectFilterGroup (payload) {
  return { type: types.DESELECT_FILTER_GROUP, payload }
}

export function searchFilters (payload) {
  return { type: types.SEARCH_FILTERS, payload }
}

export function applyFilters (payload) {
  return { type: types.APPLY_FILTERS, payload }
}

export function clearFilters () {
  return { type: types.CLEAR_FILTERS }
}

export function removeActiveValue (payload) {
  return { type: types.REMOVE_ACTIVE_VALUE, payload }
}

export function setFilterValue (payload) {
  return { type: types.SET_FILTER_VALUE, payload }
}

export function setStandaloneFilterValues () {
  return { type: types.SET_STANDALONE_FILTER_VALUES }
}

export function saveOrganizationFilter () {
  return { type: types.SAVE_ORGANIZATION_FILTER }
}

export function saveOrganizationFilterSuccess (payload) {
  return { type: types.SAVE_ORGANIZATION_FILTER_SUCCESS, payload }
}

export function saveOrganizationFilterFail (payload) {
  return { type: types.SAVE_ORGANIZATION_FILTER_FAIL, payload }
}

export function togglePopper(payload) {
  return { type: types.TOGGLE_POPPER, payload }
}
