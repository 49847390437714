import * as types from '../constants/action-types/role'

function initialState () {
  return {
    permissions: {},
    categories: [],
    dashboards: [],
    loading: false,
    saving: false,
    deleting: false,
    changedRole: null,
    error: null
  }
}

function getPermissions (state) {
  return {
    ...state,
    loading: true
  }
}

function getPermissionsSuccess (state, { payload: { permissions, categories, dashboards } }) {
  return {
    ...state,
    permissions,
    categories,
    dashboards,
    loading: false
  }
}

function getPermissionsFail (state, { payload: error }) {
  return {
    ...state,
    error,
    loading: false
  }
}

function createRole (state) {
  return {
    ...state,
    changedRole: {
      name: '',
      default: false,
      permissions: []
    }
  }
}

function selectRole (state, { payload: role }) {
  return {
    ...state,
    changedRole: {
      ...role,
      permissions: state.permissions[role.uuid]
    }
  }
}

function editRole (state, { payload: { field, value } }) {
  return {
    ...state,
    changedRole: {
      ...state.changedRole,
      [field]: value
    }
  }
}

function changePermission (state, { payload: { dashboardId, value } }) {
  const index = state.changedRole.permissions.findIndex(permission => permission.dashboardId === dashboardId)

  return {
    ...state,
    changedRole: {
      ...state.changedRole,
      permissions: index === -1 ? (
        [
          ...state.changedRole.permissions,
          { dashboardId, value }
        ]
      ) : (
        [
          ...state.changedRole.permissions.slice(0, index),
          { dashboardId, value },
          ...state.changedRole.permissions.slice(index + 1)
        ]
      )
    }
  }
}

function saveRole (state) {
  return {
    ...state,
    saving: true
  }
}

function saveRoleSuccess (state, { payload: { role, permissions } }) {
  return {
    ...state,
    saving: false,
    permissions: {
      ...state.permissions,
      [role.uuid]: [...permissions]
    },
    changedRole: {
      ...state.changedRole,
      ...role
    }
  }
}

function saveRoleFail (state, { payload: error }) {
  return {
    ...state,
    error,
    saving: false
  }
}

function deleteRole (state) {
  return {
    ...state,
    deleting: true
  }
}

function deleteRoleSuccess (state) {
  return {
    ...state,
    deleting: false
  }
}

function deleteRoleFail (state, { payload: error }) {
  return {
    ...state,
    error,
    deleting: false
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case types.GET_PERMISSIONS:
      return getPermissions(state)
    case types.GET_PERMISSIONS_SUCCESS:
      return getPermissionsSuccess(state, action)
    case types.GET_PERMISSIONS_FAIL:
      return getPermissionsFail(state, action)
    case types.CREATE_ROLE:
      return createRole(state)
    case types.SELECT_ROLE:
      return selectRole(state, action)
    case types.EDIT_ROLE:
      return editRole(state, action)
    case types.CHANGE_PERMISSION:
      return changePermission(state, action)
    case types.SAVE_ROLE:
      return saveRole(state)
    case types.SAVE_ROLE_SUCCESS:
      return saveRoleSuccess(state, action)
    case types.SAVE_ROLE_FAIL:
      return saveRoleFail(state, action)
    case types.DELETE_ROLE:
      return deleteRole(state)
    case types.DELETE_ROLE_SUCCESS:
      return deleteRoleSuccess(state, action)
    case types.DELETE_ROLE_FAIL:
      return deleteRoleFail(state, action)
    default:
      return state
  }
}
