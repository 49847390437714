import * as types from '../constants/action-types/organization'
import { chain } from 'lodash'

function initialState () {
  return {
    all: {},
    selectedKey: null,
    loading: false,
    error: null,
    editorOpen: false,
    changedOrganization: null,
    saving: false,
    deleting: false,
    clearingCache: false,
    dataSources: []
  }
}

function impersonateOrganization (state, { payload: { sid } }) {
  return {
    ...state,
    selectedKey: sid
  }
}

function openOrganizationEditor (state) {
  return {
    ...state,
    editorOpen: true
  }
}

function closeOrganizationEditor (state) {
  return {
    ...state,
    editorOpen: false
  }
}

function unselectOrganization (state) {
  return {
    ...state,
    selectedKey: null
  }
}

function loadOrganizations (state) {
  return {
    ...state,
    loading: true
  }
}

function loadOrganizationsSuccess (state, { payload }) {
  return {
    ...state,
    all: chain(payload.organizations)
      .map(({
        schema_name: schemaName,
        new_data_model: newDataModel,
        has_connection_string: hasConnectionString,
        has_sftp_config: hasSftpConfigString,
        creation_date: creationDate,
        authorization_link: link,
        error_msg: error,
        etl_sid: etlSid,
        data_source_id: dataSourceId,
        ipeds_unitid: ipedsUnitId,
        s3_bucket_name: s3BucketName,
        insights_db_name: insightsDbName,
        sftp_config: sftpConfig,
        heroku_addon_name: herokuAddonName,
        ...org
      }) => ({
        ...org,
        schemaName,
        newDataModel,
        hasConnectionString,
        hasSftpConfigString,
        creationDate,
        link,
        error,
        connectionString: '',
        etlSid,
        dataSourceId,
        ipedsUnitId,
        herokuAddonName,
        s3BucketName,
        insightsDbName,
        sftpConfig,
        selected: false,
        loading: false
      }))
      .keyBy('sid')
      .value(),
    loading: false
  }
}

function loadOrganizationsFail (state, { payload: error }) {
  return {
    ...state,
    error,
    loading: false
  }
}

function loadOrganizationsCanceled (state) {
  return {
    ...state,
    loading: false
  }
}

function createOrganization (state) {
  return {
    ...state,
    changedOrganization: {
      uuid: null,
      sid: null,
      state: null,
      name: '',
      schemaName: '',
      connectionString: '',
      new_data_model: false,
      etlSid: '',
      dataSourceId: '',
      ipedsUnitId: '',
      herokuAddonName: '',
      s3BucketName: '',
      insightsDbName: '',
      sftpConfig: ''
    }
  }
}

function selectOrganizationToEdit (state, { payload: sid }) {
  return {
    ...state,
    changedOrganization: state.all[sid]
  }
}

function unselectOrganizationToEdit (state) {
  return {
    ...state,
    changedOrganization: null
  }
}

function editOrganization (state, { payload: { field, value } }) {
  return {
    ...state,
    changedOrganization: {
      ...state.changedOrganization,
      [field]: value
    }
  }
}

function saveOrganization (state) {
  return {
    ...state,
    saving: true
  }
}

function saveOrganizationSuccess (state, { payload: { organization, hasConnectionString, hasSftpConfigString } }) {
  const { [organization.sid]: previousOrganization, ...organizations } = state.all

  const newState = {
    created: true,
    hasConnectionString,
    hasSftpConfigString,
    connectionString: '',
    sftpConfig: ''
  }

  return {
    ...state,
    saving: false,
    all: {
      ...organizations,
      [organization.sid]: {
        ...organization,
        ...newState
      }
    },
    changedOrganization: {
      ...state.changedOrganization,
      ...newState
    }
  }
}

function saveOrganizationFail (state, { payload: error }) {
  return {
    ...state,
    saving: false,
    error
  }
}

function deleteOrganization (state) {
  return {
    ...state,
    deleting: true
  }
}

function deleteOrganizationSuccess (state, { payload: organization }) {
  const { [organization.sid]: deletedOrganization, ...organizations } = state.all

  return {
    ...state,
    deleting: false,
    all: organizations
  }
}

function deleteOrganizationFail (state, { payload: error }) {
  return {
    ...state,
    deleting: false,
    error
  }
}

function clearOrganizationCache (state) {
  return {
    ...state,
    clearingCache: true
  }
}

function clearOrganizationCacheEnd (state) {
  return {
    ...state,
    clearingCache: false
  }
}

function loadDataSources (state) {
  return {
    ...state,
    loading: true
  }
}

function loadDataSourcesSuccess (state, { payload }) {
  return {
    ...state,
    dataSources: payload.data_sources,
    loading: false
  }
}

function loadDataSourcesFail (state, { payload: error }) {
  return {
    ...state,
    error,
    loading: false
  }
}

export default function organization (state = initialState(), action) {
  switch (action.type) {
    case types.IMPERSONATE_ORGANIZATION:
      return impersonateOrganization(state, action)
    case types.LOAD_ORGANIZATIONS:
      return loadOrganizations(state)
    case types.LOAD_ORGANIZATIONS_SUCCESS:
      return loadOrganizationsSuccess(state, action)
    case types.LOAD_ORGANIZATIONS_FAIL:
      return loadOrganizationsFail(state, action)
    case types.LOAD_ORGANIZATIONS_CANCELED:
      return loadOrganizationsCanceled(state, action)
    case types.STOP_IMPERSONATING:
      return unselectOrganization(state)
    case types.CREATE_ORGANIZATION:
      return createOrganization(state)
    case types.SELECT_ORGANIZATION_TO_EDIT:
      return selectOrganizationToEdit(state, action)
    case types.UNSELECT_ORGANIZATION_TO_EDIT:
      return unselectOrganizationToEdit(state)
    case types.EDIT_ORGANIZATION:
      return editOrganization(state, action)
    case types.SAVE_ORGANIZATION:
      return saveOrganization(state)
    case types.SAVE_ORGANIZATION_SUCCESS:
      return saveOrganizationSuccess(state, action)
    case types.SAVE_ORGANIZATION_FAIL:
      return saveOrganizationFail(state, action)
    case types.DELETE_ORGANIZATION:
      return deleteOrganization(state)
    case types.DELETE_ORGANIZATION_SUCCESS:
      return deleteOrganizationSuccess(state, action)
    case types.DELETE_ORGANIZATION_FAIL:
      return deleteOrganizationFail(state, action)
    case types.CLEAR_ORGANIZATION_CACHE:
      return clearOrganizationCache(state)
    case types.CLEAR_ORGANIZATION_CACHE_SUCCESS:
    case types.CLEAR_ORGANIZATION_CACHE_FAIL:
      return clearOrganizationCacheEnd(state, action)
    case types.OPEN_ORGANIZATION_EDITOR:
      return openOrganizationEditor(state)
    case types.CLOSE_ORGANIZATION_EDITOR:
      return closeOrganizationEditor(state)
    case types.LOAD_DATASOURCES:
      return loadDataSources(state)
    case types.LOAD_DATASOURCES_SUCCESS:
      return loadDataSourcesSuccess(state, action)
    case types.LOAD_DATASOURCES_FAIL:
      return loadDataSourcesFail(state, action)
    default:
      return state
  }
}
