// Errors
export const ErrorCode = {
  E0000: 'Operation canceled by the user',
  E0001: 'Oops! Something went wrong...', // Internal error
  E0002: 'The user does not belong to any organization'
}

// User types
export const UserType = {
  TARGETX_ADMIN: 'TARGETX_ADMIN',
  SCHOOL_ADMIN: 'SCHOOL_ADMIN',
  NORMAL_USER: 'NORMAL_USER'
}

// Message types
export const MessageType = {
  ERROR: 'ERROR',
  DELETE: 'DELETE',
  WARNING: 'WARNING',
  INFO: 'INFO',
  SUCCESS: 'SUCCESS'
}

// Confirmation types
export const ConfirmationType = {
  DELETE: 'DELETE',
  CANCEL: 'CANCEL'
}

// Draggable items
export const Draggable = {
  MAPPING: 'MAPPING'
}

// Mapping columns
export const MappingColumns = {
  AVAILABLE: 'AVAILABLE',
  EXISTING: 'EXISTING'
}

// Error tags
export const ErrorTags = {
  TARGETS_ERROR: 'TARGETS_ERROR'
}

// Roles
export const Role = {
  ADMIN: 'Admin'
}

// Organization Status
export const OrganizationStatus = {
  CREATED: 'Created',
  PENDING_AUTHORIZATION: 'Pending Authorization',
  ACTIVE: 'Active',
  INVALID: 'Invalid',
  ERROR: 'Error'
}

export const ENVIRONMENT = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production'
}

export const EquityDashboardFunnelAttritionSelector = [
  {
    displayValue: 'Ethnicity',
    value: 6500,
    dashboardId: 6500
  },
  {
    displayValue: 'Gender',
    value: 6501,
    dashboardId: 6501
  }
]

export const EquityDashboardAcademicProgramsSelector = [
  {
    displayValue: 'Ethnicity',
    value: 6510,
    dashboardId: 6510
  },
  {
    displayValue: 'Gender',
    value: 6511,
    dashboardId: 6511
  }
]

export const EquityDashboardEventsSelector = [
  {
    displayValue: 'Ethnicity',
    value: 6520,
    dashboardId: 6520
  },
  {
    displayValue: 'Gender',
    value: 6521,
    dashboardId: 6521
  }
]
