import * as types from '../constants/action-types/mapping'

function initialState () {
  return {
    mapper: null,
    mappings: null,
    changedMappings: null,
    loading: false,
    saving: false,
    mobileDialogOpen: false,
    currentMobileMapperIndex: 0,
    expandedMobileMapperUUID: null,
    error: null
  }
}

function getMapping (state) {
  return {
    ...state,
    loading: true
  }
}

function getMappingSuccess (state, { payload }) {
  payload.mapper.available.forEach(({ uuid }) => {
    if (!payload.mappings.json[uuid]) payload.mappings.json[uuid] = []
  })

  return {
    ...state,
    mapper: payload.mapper,
    mappings: payload.mappings.json,
    changedMappings: payload.mappings.json,
    loading: false,
    error: null
  }
}

function getMappingFail (state, { payload }) {
  return {
    ...state,
    loading: false,
    error: payload
  }
}

function saveMapping (state) {
  return {
    ...state,
    saving: true
  }
}

function saveMappingSuccess (state) {
  return {
    ...state,
    saving: false,
    mobileDialogOpen: false,
    currentMobileMapperIndex: 0,
    expandedMobileMapperUUID: null,
    mappings: state.changedMappings
  }
}

function saveMappingFail (state, { payload }) {
  return {
    ...state,
    saving: false,
    error: payload
  }
}

function addMapping (state, { payload: { value, target } }) {
  return {
    ...state,
    changedMappings: {
      ...state.changedMappings,
      [target]: state.changedMappings[target] ? [...state.changedMappings[target], value] : [value]
    }
  }
}

function removeMapping (state, { payload: { value, source } }) {
  return {
    ...state,
    changedMappings: {
      ...state.changedMappings,
      [source]: state.changedMappings[source].filter(available => available !== value)
    }
  }
}

function moveMapping (state, { payload: { value, target, source } }) {
  return {
    ...state,
    changedMappings: {
      ...state.changedMappings,
      [source]: state.changedMappings[source].filter(available => available !== value),
      [target]: state.changedMappings[target] ? [...state.changedMappings[target], value] : [value]
    }
  }
}

function clearMappings (state, { payload: source }) {
  return {
    ...state,
    changedMappings: {
      ...state.changedMappings,
      [source]: []
    }
  }
}

function startMobileMapping (state) {
  return {
    ...state,
    mobileDialogOpen: true
  }
}

function cancelMobileMapping (state) {
  return {
    ...state,
    mobileDialogOpen: false,
    currentMobileMapperIndex: 0,
    changedMappings: state.mappings
  }
}

function changeCurrentMobileMapper (state, { payload }) {
  return {
    ...state,
    currentMobileMapperIndex: state.currentMobileMapperIndex + payload
  }
}

function toggleExpandedMobileMapper (state, { payload: uuid }) {
  return {
    ...state,
    expandedMobileMapperUUID: state.expandedMobileMapperUUID === uuid ? null : uuid
  }
}

export default function mapping (state = initialState(), action) {
  switch (action.type) {
    case types.GET_MAPPING:
      return getMapping(state)
    case types.GET_MAPPING_SUCCESS:
      return getMappingSuccess(state, action)
    case types.GET_MAPPING_FAIL:
      return getMappingFail(state, action)
    case types.SAVE_MAPPING:
      return saveMapping(state)
    case types.SAVE_MAPPING_SUCCESS:
      return saveMappingSuccess(state)
    case types.SAVE_MAPPING_FAIL:
      return saveMappingFail(state, action)
    case types.ADD_MAPPING:
      return addMapping(state, action)
    case types.REMOVE_MAPPING:
      return removeMapping(state, action)
    case types.MOVE_MAPPING:
      return moveMapping(state, action)
    case types.CLEAR_MAPPINGS:
      return clearMappings(state, action)
    case types.START_MOBILE_MAPPING:
      return startMobileMapping(state)
    case types.CANCEL_MOBILE_MAPPING:
      return cancelMobileMapping(state)
    case types.CHANGE_CURRENT_MOBILE_MAPPER:
      return changeCurrentMobileMapper(state, action)
    case types.TOGGLE_EXPANDED_MOBILE_MAPPER:
      return toggleExpandedMobileMapper(state, action)
    default:
      return state
  }
}
