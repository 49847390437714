import { connect } from 'react-redux'
import { loadMe } from '../../thunks/me'
import PrivateRoute from '../../components/routes/PrivateRoute'

const mapStateToProps = (state) => ({
  loading: state.me.loading,
  inMaintenance: state.me.inMaintenance,
  user: state.me
})

const mapDispatchToProps = {
  loadMe
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute)
