import PropTypes from 'prop-types'
import React from 'react'
import { Redirect, Route } from 'react-router'
import Loading from '../commons/loading-dots/LoadingDots'
import AsyncPages from './AsyncPages'
import Paths from './Paths'

class PrivateRoute extends React.Component {
  componentDidMount () {
    this.props.loadMe()
  }

  render () {
    const { user } = this.props
    window && window.heap && window.heap.identify(user.userId)
    window && window.heap && window.heap.addUserProperties({
      'First Name': user.firstName,
      'Last Name': user.lastName,
      'Role': user.roles,
      'Org ID': user.organizationUUID,
      'Org Name': user.organizationName,
      ...(user.salesforceProfile ? {
        'Salesforce User ID': user.salesforceProfile.user_id,
        'Salesforce Org ID': user.salesforceProfile.organization_id
      } : {})
    })
    if (this.props.loading) {
      return <Loading />
    }

    if (this.props.inMaintenance) {
      return <Redirect to={Paths.MAINTENANCE.path} />
    }

    return <Route path={Paths.HOME.path} component={AsyncPages.Home} />
  }
}

PrivateRoute.propTypes = {
  loading: PropTypes.bool.isRequired,
  loadMe: PropTypes.func.isRequired
}

export default PrivateRoute
