import * as types from '../constants/action-types/filter'
import { persistFilters, retrieveFilters } from '../handlers/localStorage'

function initialState () {
  return {
    filters: [],
    activeFilters: {},
    loading: false,
    saving: false,
    panelOpen: false,
    selectedFilterUUID: null,
    searchText: '',
    error: null,
    selectedFilterGroup: [],
    popperObj:{isVisible: false, columnIndex: null}
  }
}

function loadFilters (state) {
  return {
    ...state,
    filters: [],
    loading: true,
    error: null
  }
}

function loadFiltersSuccess (state, { payload: filters }) {
  return {
    ...state,
    loading: false,
    filters
  }
}

function loadFiltersFail (state, { payload: error }) {
  return {
    ...state,
    loading: false,
    error
  }
}

function loadFilter (state, { payload: filterUUID }) {
  return {
    ...state,
    filters: [
      ...state.filters.filter(filter => filter.uuid !== filterUUID),
      {
        ...state.filters.find(filter => filter.uuid === filterUUID),
        loading: true,
        error: null
      }
    ]
  }
}

function loadFilterSuccess (state, { payload: filter }) {
  return {
    ...state,
    filters: state.filters.map(f => {
      if (f.uuid !== filter.uuid) return f

      return {
        ...f,
        ...filter,
        loading: false,
        selectedValues: f.selectedValues && filter.values.includes(f.selectedValues[0])
          ? [f.selectedValues[0]]
          : [filter.values[0]]
      }
    })
  }
}

function loadFilterFail (state, { payload: { filterUUID, error } }) {
  return {
    ...state,
    filters: state.filters.map(filter => {
      if (filter.uuid !== filterUUID) return filter

      return {
        ...filter,
        loading: false,
        error
      }
    })
  }
}

function showFiltersPanel (state) {
  return {
    ...state,
    panelOpen: true
  }
}

function hideFiltersPanel (state) {
  return {
    ...state,
    panelOpen: false
  }
}

function selectFilter (state, { payload: filter }) {
  return {
    ...state,
    selectedFilterUUID: filter.uuid,
    searchText: ''
  }
}

function addSelectedValue (state, { payload: value }) {
  return {
    ...state,
    filters: state.filters.map(filter => {
      if (filter.uuid !== state.selectedFilterUUID) return filter

      return {
        ...filter,
        selectedValues: [
          ...filter.selectedValues,
          value
        ]
      }
    })
  }
}

function removeSelectedValue (state, { payload }) {
  return {
    ...state,
    filters: state.filters.map(filter => {
      if (filter.uuid !== state.selectedFilterUUID) return filter

      return {
        ...filter,
        selectedValues: filter.selectedValues.filter(value => value !== payload)
      }
    })
  }
}

function selectFilterGroup (state, { payload: filterGroup }) {
  const selectedFilters = [...state.selectedFilterGroup].slice()
  const data = {
    filter_name: state.filters.find((el) => el.uuid === filterGroup.filter_uuid) !== undefined ? state.filters.find((el) => el.uuid === filterGroup.filter_uuid).name : null,
    filter_uuid: filterGroup.filter_uuid,
    variable_name: state.filters.find((el) => el.uuid === filterGroup.filter_uuid) !== undefined ? state.filters.find((el) => el.uuid === filterGroup.filter_uuid).variable_name : null,
    filter_group_name: filterGroup.name,
    values: filterGroup.values,
    uuid: filterGroup.uuid
  }
  if (selectedFilters.length) {
    const index = selectedFilters.findIndex((ol) => ol.filter_uuid === filterGroup.filter_uuid && ol.filter_group_name === filterGroup.name)
    if (index === -1) {
      selectedFilters.push(data)
    } else {
      selectedFilters[index] = data
    }
  } else {
    selectedFilters.push(data)
  }
  return {
    ...state,
    filters: state.filters.map(filter => {
      if (filter.uuid !== filterGroup.filter_uuid) return filter

      return {
        ...filter,
        selectedValues: [
          ...filter.selectedValues.filter(value => !filterGroup.values.includes(value)),
          ...filterGroup.values
        ]
      }
    }),
    selectedFilterGroup: [...selectedFilters]
  }
}

function deselectFilterGroup (state, { payload: { filterGroups, filterGroup } }) {
  const selectedFilter = state.filters.find(filter => filter.uuid === state.selectedFilterUUID)
  const selectedFilterGroups = filterGroups.filter(fg => (
    fg.uuid !== filterGroup.uuid &&
    fg.values.every(value => selectedFilter.selectedValues.includes(value))
  ))
  const selectedGroup = [...state.selectedFilterGroup].slice()

  return {
    ...state,
    filters: state.filters.map(filter => {
      if (filter.uuid !== filterGroup.filter_uuid) return filter

      return {
        ...filter,
        selectedValues: filter.selectedValues.filter(value => (
          !filterGroup.values.includes(value) ||
          selectedFilterGroups.some(filterGroup => filterGroup.values.includes(value))
        ))
      }
    }),
    selectedFilterGroup: selectedGroup.filter(el => el.uuid !== filterGroup.uuid)
  }
}

function searchFilters (state, { payload: searchText }) {
  return {
    ...state,
    searchText
  }
}

function applyFilters (state, { payload: closePanel }) {
  return {
    ...state,
    panelOpen: closePanel ? false : state.panelOpen,
    searchText: '',
    activeFilters: state.filters
      .filter(filter => filter.selectedValues.length > 0)
      .reduce((filters, filter) => ({ ...filters, [filter.uuid]: filter.selectedValues }), {})
  }
}

function clearFilters (state) {
  localStorage.removeItem('selectedFilterGroup')
  return {
    ...state,
    panelOpen: false,
    searchText: '',
    activeFilters: state.filters
      .filter(filter => filter.standalone && filter.selectedValues.length > 0)
      .reduce((filters, filter) => ({ ...filters, [filter.uuid]: filter.selectedValues }), {}),
    filters: state.filters.map(filter => {
      if (filter.standalone) return filter

      return {
        ...filter,
        selectedValues: []
      }
    }),
    selectedFilterGroup: []
  }
}

function removeActiveValue (state, { payload: { filterUUID, value } }) {
  return {
    ...state,
    filters: state.filters.map(filter => {
      if (filter.uuid !== filterUUID) return filter

      return {
        ...filter,
        selectedValues: filter.selectedValues.filter(v => v !== value)
      }
    })
  }
}

function setFilterValue (state, { payload: { filterUUID, value } }) {
  return {
    ...state,
    filters: state.filters.map(filter => {
      if (filter.uuid !== filterUUID) return filter

      return {
        ...filter,
        selectedValues: [value]
      }
    })
  }
}

function setStandaloneFilterValues (state) {
  return {
    ...state,
    filters: state.filters.map(filter => {
      if (!filter.standalone) return filter

      const value = filter.selectedValues && filter.values.includes(filter.selectedValues[0])
        ? filter.selectedValues[0]
        : filter.values.includes(filter.default_value)
          ? filter.default_value
          : filter.values[0]

      return {
        ...filter,
        selectedValues: [value]
      }
    })
  }
}

function saveOrganizationFilter (state) {
  return {
    ...state,
    saving: true
  }
}

function saveOrganizationFilterSuccess (state, { payload: { filterUUID, filter } }) {
  return {
    ...state,
    saving: false,
    filters: state.filters.map(f => {
      if (f.uuid !== filterUUID) return f

      return {
        ...f,
        ...filter
      }
    }),
    activeFilters: {
      ...state.activeFilters,
      [filterUUID]: [filter.default_value]
    }
  }
}

function saveOrganizationFilterFail (state, { payload: error }) {
  return {
    ...state,
    saving: false,
    error
  }
}

function togglePopper(state, Obj) {
  const { payload } = Obj
  return {
    ...state,
    popperObj: {columnIndex:payload.columnIndex,isVisible:payload.isVisible},
  }
  
}

export default function filter (state = initialState(), action) {
  switch (action.type) {
    
    case types.LOAD_FILTERS:
      return loadFilters(state)
    case types.LOAD_FILTERS_SUCCESS:
      return retrieveFilters(loadFiltersSuccess(state, action))
    case types.LOAD_FILTERS_FAIL:
      return loadFiltersFail(state, action)
    case types.LOAD_FILTER:
      return loadFilter(state, action)
    case types.LOAD_FILTER_SUCCESS:
      return loadFilterSuccess(state, action)
    case types.LOAD_FILTER_FAIL:
      return loadFilterFail(state, action)
    case types.SHOW_FILTERS_PANEL:
      return showFiltersPanel(state)
    case types.HIDE_FILTERS_PANEL:
      return hideFiltersPanel(state)
    case types.SELECT_FILTER:
      return selectFilter(state, action)
    case types.ADD_SELECTED_VALUE:
      return addSelectedValue(state, action)
    case types.REMOVE_SELECTED_VALUE:
      return removeSelectedValue(state, action)
    case types.SELECT_FILTER_GROUP:
      return selectFilterGroup(state, action)
    case types.DESELECT_FILTER_GROUP:
      return deselectFilterGroup(state, action)
    case types.SEARCH_FILTERS:
      return searchFilters(state, action)
    case types.APPLY_FILTERS:
      return persistFilters(applyFilters(state, action))
    case types.CLEAR_FILTERS:
      return persistFilters(clearFilters(state))
    case types.REMOVE_ACTIVE_VALUE:
      return removeActiveValue(state, action)
    case types.SET_FILTER_VALUE:
      return setFilterValue(state, action)
    case types.SET_STANDALONE_FILTER_VALUES:
      return setStandaloneFilterValues(state)
    case types.SAVE_ORGANIZATION_FILTER:
      return saveOrganizationFilter(state)
    case types.SAVE_ORGANIZATION_FILTER_SUCCESS:
      return persistFilters(saveOrganizationFilterSuccess(state, action))
    case types.SAVE_ORGANIZATION_FILTER_FAIL:
      return saveOrganizationFilterFail(state, action)
    case types.TOGGLE_POPPER:
      return togglePopper(state, action)
    default:
      return state
  }
}
