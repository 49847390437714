import { createTheme } from '@material-ui/core/styles'
import config from './config'
import overrides from './overrides'

const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: 'proxima-nova, open-sans, sans-serif',
    fontSize: 16,
  },
  palette: {
    primary: {
      main: config.themeColors.primary
    },
    secondary: {
      main: config.themeColors.success
    }
  },
  header: {
    height: 60
  },
  leftMenu: {
    fullWidth: 300,
    miniWidth: 60,
    backgroundColor: '#0A2233'
  },
  overrides
})

export default theme
