import { legacy_createStore as createStore, compose, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import reducers from './reducers'

const history = createBrowserHistory()

const middleware = applyMiddleware(
  routerMiddleware(history),
  thunk
)

const composeEnhancers = process.env.REACT_APP_DEV_TOOLS === 'true' ? composeWithDevTools : compose

const store = createStore(
  reducers,
  {},
  composeEnhancers(middleware)
)

export { history, store }
