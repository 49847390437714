import Loadable from 'react-loadable'
import Loading from '../commons/loading-dots/LoadingDots'

const Containers = [
  // ↓ Main pages ↓

  {
    name: 'UnknownError',
    path: 'error-pages/UnknownErrorContainer'
  },
  {
    name: 'Maintenance',
    path: 'error-pages/MaintenanceContainer'
  },
  {
    name: 'NotAllowed',
    path: 'error-pages/NotAllowedContainer'
  },
  {
    name: 'NotFound',
    path: 'error-pages/NotFoundContainer'
  },
  {
    name: 'Home',
    path: 'home/HomeContainer'
  },
  {
    name: 'Help',
    path: 'help/HelpContainer'
  },
  {
    name: 'Dashboards',
    path: 'dashboards/DashboardsContainer'
  },
  {
    name: 'Dashboard',
    path: 'dashboards/DashboardContainer'
  },

  // ↓ School admin settings ↓

  {
    name: 'SchoolAdminDashboards',
    path: 'dashboards/SchoolAdminDashboardsContainer'
  },
  {
    name: 'Mapping',
    path: 'mapping/MappingContainer'
  },
  {
    name: 'MappingDataField',
    path: 'mapping/data-field'
  },
  {
    name: 'MappingStandardizedValues',
    path: 'mapping/standardized-values'
  },
  {
    name: 'Targets',
    path: 'targets/TargetsContainer'
  },
  {
    name: 'Users',
    path: 'users/UsersContainer'
  },
  {
    name: 'Roles',
    path: 'roles/RolesContainer'
  },
  {
    name: 'RoleEditor',
    path: 'roles/RoleEditorContainer'
  },
  {
    name: 'FilterGroups',
    path: 'filter-groups/FilterGroupsContainer'
  },
  {
    name: 'SchoolConfig',
    path: 'school-config/SchoolConfigContainer'
  },

  // ↓ TargetX admin settings ↓

  {
    name: 'Organizations',
    path: 'organizations/OrganizationsContainer'
  },
  {
    name: 'OrganizationEditor',
    path: 'organizations/OrganizationEditorContainer'
  },
  {
    name: 'ViewEditor',
    path: 'views/ViewEditorContainer'
  },
  {
    name: 'ViewEditorForm',
    path: 'views/ViewEditorFormContainer'
  },
  {
    name: 'ViewEditorPreview',
    path: 'views/ViewEditorPreviewContainer'
  },
  {
    name: 'FilterEditor',
    path: 'filter-editor/FilterEditorContainer'
  },
  {
    name: 'FilterEditorForm',
    path: 'filter-editor/FilterEditorFormContainer'
  },
  {
    name: 'Categories',
    path: 'categories/CategoriesContainer'
  },
  {
    name: 'CategoryDashboards',
    path: 'dashboards/CategoryDashboardsContainer'
  },
  {
    name: 'LoggerDashboards',
    path: 'logger/LoggerContainer'
  },
  {
    name: 'ContentMigration',
    path: 'content-migration/ContentMigration'
  }
]

const AsyncPages = Containers.reduce((pages, container) => ({
  ...pages,
  [container.name]: Loadable({
    loader: () => import(`../../containers/${container.path}`),
    loading: Loading
  })
}), {})

export default AsyncPages
