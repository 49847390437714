export const getViewFilters = (state) => {
  const views = Object.values(state.view)
  const filters = views.flatMap(view => view.filters).filter(filter => filter !== undefined)
  return [...new Set(filters)]
}

export const getActiveViewFilters = (state, viewId) => {
  const view = state.view[viewId]
  const filters = {}
  // Forcing to build request params when we switch terms
  view.filters = undefined
  Object.entries(state.filter.activeFilters).forEach(([filterUUID, selectedValues]) => {
    if (!view.filters || view.filters.includes(filterUUID)) {
      const filter = state.filter.filters.find(filter => filter.uuid === filterUUID)
      if (filter) filters[filter.variable_name] = selectedValues
    }
  })

  return filters
}
