import api from '../handlers/api'
import * as actions from '../actions/filter-group'
import { addNotification } from '../actions/notification'
import { getCurrentOrganizationUUID } from '../selectors/organization'
import { MessageType } from '../constants'

export function getFilterGroups () {
  return async (dispatch, getState) => {
    try {
      dispatch(actions.getFilterGroups())
      const state = getState()
      const organizationUUID = getCurrentOrganizationUUID(state)

      const response = await api.get(`/organizations/${organizationUUID}/filter-groups`)
      dispatch(actions.getFilterGroupsSuccess(response.data))
    } catch (error) {
      dispatch(actions.getFilterGroupsFail(error.message))
      dispatch(addNotification({
        message: 'Error loading filter groups',
        type: MessageType.ERROR
      }))
    }
  }
}

export function saveFilterGroup (filterGroup, isNew) {
  return async (dispatch, getState) => {
    try {
      dispatch(actions.saveFilterGroup())
      const state = getState()
      const organizationUUID = getCurrentOrganizationUUID(state)
      const filter = state.filter.filters.find(filter => filter.uuid === filterGroup.filter_uuid)

      if (isNew) {
        const { uuid } = (await api.post(`/organizations/${organizationUUID}/filter-groups`, filterGroup)).data
        filterGroup.uuid = uuid
        window.mixpanel.track('Filter group created', { 'Filter group': filterGroup.name, Filter: filter.name, Values: filterGroup.values.join(', ') })
      } else {
        const body = { name: filterGroup.name, values: filterGroup.values }
        await api.patch(`/organizations/${organizationUUID}/filter-groups/${filterGroup.uuid}`, body)
        const previous = state.filterGroup.filterGroups.find(fg => fg.uuid === filterGroup.uuid)
        window.mixpanel.track('Filter group updated', { 'Filter group': filterGroup.name, Filter: filter.name, From: previous.values.join(', '), To: filterGroup.values.join(', ') })
      }

      const date = Date.now()
      const user = `${state.me.firstName} ${state.me.lastName}`
      dispatch(actions.saveFilterGroupSuccess({ filterGroup, isNew, date, user }))
    } catch (error) {
      dispatch(actions.saveFilterGroupFail(error.message))
      dispatch(addNotification({
        message: 'Error saving filter group',
        type: MessageType.ERROR
      }))
      window.mixpanel.track(isNew ? 'Filter group creation error' : 'Filter group update error', { 'Filter group': filterGroup.name })
    }
  }
}

export function deleteFilterGroup (filterGroupUUID) {
  return async (dispatch, getState) => {
    const state = getState()
    const filterGroup = state.filterGroup.filterGroups.find(filterGroup => filterGroup.uuid === filterGroupUUID)
    const organizationUUID = getCurrentOrganizationUUID(state)

    try {
      dispatch(actions.deleteFilterGroup())
      await api.delete(`/organizations/${organizationUUID}/filter-groups/${filterGroupUUID}`)
      dispatch(actions.deleteFilterGroupSuccess(filterGroupUUID))
      window.mixpanel.track('Filter group deleted', { 'Filter group': filterGroup.name })
    } catch (error) {
      dispatch(actions.deleteFilterGroupFail(error.message))
      dispatch(addNotification({
        message: 'Error deleting filter group',
        type: MessageType.ERROR
      }))
      window.mixpanel.track('Filter group delete error', { 'Filter group': filterGroup.name })
      return Promise.reject(error)
    }
  }
}
