import React from 'react'
import { createRoot} from 'react-dom/client'
import mixpanel from './handlers/mixpanel'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import 'core-js'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './fonts.css'
import './index.css'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)//, document.getElementById('root'))
serviceWorker.unregister()

window.mixpanel = mixpanel
