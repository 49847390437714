export const GET_MAPPING = 'GET_MAPPING'
export const GET_MAPPING_SUCCESS = 'GET_MAPPING_SUCCESS'
export const GET_MAPPING_FAIL = 'GET_MAPPING_FAIL'
export const SAVE_MAPPING = 'SAVE_MAPPING'
export const SAVE_MAPPING_SUCCESS = 'SAVE_MAPPING_SUCCESS'
export const SAVE_MAPPING_FAIL = 'SAVE_MAPPING_FAIL'
export const ADD_MAPPING = 'ADD_MAPPING'
export const REMOVE_MAPPING = 'REMOVE_MAPPING'
export const MOVE_MAPPING = 'MOVE_MAPPING'
export const CLEAR_MAPPINGS = 'CLEAR_MAPPINGS'
export const START_MOBILE_MAPPING = 'START_MOBILE_MAPPING'
export const CANCEL_MOBILE_MAPPING = 'CANCEL_MOBILE_MAPPING'
export const CHANGE_CURRENT_MOBILE_MAPPER = 'CHANGE_CURRENT_MOBILE_MAPPER'
export const TOGGLE_EXPANDED_MOBILE_MAPPER = 'TOGGLE_EXPANDED_MOBILE_MAPPER'
