import * as types from '../constants/action-types/user'

const initialState = () => ({
  users: [],
  dashboards: [],
  categories: [],
  changedUsers: [],
  saving: [],
  loading: false,
  error: null
})

const getUsers = (state) => ({
  ...state,
  loading: true
})

const getUsersSuccess = (state, { payload: { users, dashboards, categories } }) => ({
  ...state,
  users,
  changedUsers: users,
  dashboards,
  categories,
  loading: false
})

const getUsersFail = (state, { payload: error }) => ({
  ...state,
  error,
  loading: false
})

const updateUserRole = (state, { payload: { userUUID, role } }) => ({
  ...state,
  changedUsers: state.changedUsers.map(user => {
    if (user.uuid !== userUUID) return user

    return {
      ...user,
      roles: user.roles.includes(role) ? user.roles.filter(userRole => userRole !== role) : [...user.roles, role]
    }
  })
})

const saveUserRoles = (state, { payload: { userUUID } }) => ({
  ...state,
  saving: [...state.saving, userUUID]
})

const saveUserRolesSuccess = (state, { payload: { userUUID, dashboards } }) => ({
  ...state,
  saving: state.saving.filter(uuid => uuid !== userUUID),
  users: state.users.map(user => {
    if (user.uuid !== userUUID) return user

    return {
      ...user,
      roles: [...state.changedUsers.find(user => user.uuid === userUUID).roles],
      dashboards
    }
  })
})

const saveUserRolesFail = (state, { payload: { error, userUUID } }) => ({
  ...state,
  error,
  saving: state.saving.filter(uuid => uuid !== userUUID)
})

const user = (state = initialState(), action) => {
  switch (action.type) {
    case types.GET_USERS:
      return getUsers(state)
    case types.GET_USERS_SUCCESS:
      return getUsersSuccess(state, action)
    case types.GET_USERS_FAIL:
      return getUsersFail(state, action)
    case types.UPDATE_USER_ROLE:
      return updateUserRole(state, action)
    case types.SAVE_USER_ROLES:
      return saveUserRoles(state, action)
    case types.SAVE_USER_ROLES_SUCCESS:
      return saveUserRolesSuccess(state, action)
    case types.SAVE_USER_ROLES_FAIL:
      return saveUserRolesFail(state, action)
    default:
      return state
  }
}

export default user
