import * as types from '../constants/action-types/view-editor'
import { GO_TO } from '../constants/action-types/path'
import { chain } from 'lodash'

const defaultView = {
  id: '0',
  name: '',
  short_description: '',
  sql: '',
  reducer: '',
  layout: '',
  adapter: '',
  filters: [],
  sql_source: ''
}

function initialState () {
  return {
    all: {
      0: { ...defaultView }
    },
    selectedKey: null,
    loading: false,
    error: ''
  }
}

function selectView (state, { payload }) {
  return {
    ...state,
    selectedKey: payload
  }
}

function unselectView (state) {
  return {
    ...state,
    selectedKey: null
  }
}

function loadView (state, { payload }) {
  return {
    ...state,
    selectedKey: payload,
    all: {
      ...state.all,
      [payload]: {
        id: payload,
        selected: true,
        loading: true,
        deleting: false,
        error: null
      }
    },
    loading: true
  }
}

function loadViewSuccess (state, { payload }) {
  return {
    ...state,
    all: {
      ...state.all,
      [payload.id]: payload,
      0: { ...defaultView }
    },
    loading: false
  }
}

function loadViewFail (state, { payload: error }) {
  return {
    ...state,
    error,
    loading: false
  }
}

function loadViews (state) {
  return {
    ...state,
    loading: true
  }
}

function loadViewsSuccess (state, { payload }) {
  const allViews = chain(payload)
    .map((view) => ({
      ...view,
      id: view.id,
      selected: false,
      loading: false,
      deleting: false,
      error: null
    }))
    .keyBy('id')
    .value()
  return {
    ...state,
    all: {
      ...allViews,
      0: { ...defaultView }
    },
    loading: false
  }
}

function loadViewsFail (state, { payload: error }) {
  return {
    ...state,
    error,
    loading: false
  }
}

function saveViewEditor (state) {
  return {
    ...state,
    all: {
      ...state.all,
      [state.selectedKey]: {
        ...state.all[state.selectedKey],
        loading: true
      }
    }
  }
}

function saveViewEditorSuccess (state, { payload }) {
  return {
    ...state,
    all: {
      ...state.all,
      0: { ...defaultView },
      [payload.id]: {
        ...state.all[payload.id],
        ...payload,
        loading: false
      }
    }
  }
}

function deleteViewEditor (state, { payload }) {
  return {
    ...state,
    all: {
      ...state.all,
      [payload]: {
        ...state.all[payload],
        deleting: true
      }
    }
  }
}

function deleteViewEditorSuccess (state, { payload }) {
  const newAll = { ...state.all }
  delete newAll[payload]
  return {
    ...state,
    all: newAll
  }
}

function deleteViewEditorFail (state, { payload: { viewId } }) {
  return {
    ...state,
    all: {
      ...state.all,
      [viewId]: {
        ...state.all[viewId],
        deleting: false
      }
    }
  }
}

function viewEditor (state = initialState(), action) {
  switch (action.type) {
    case types.SELECT_VIEW_EDITOR:
      return selectView(state, action)
    case types.LOAD_VIEW_EDITOR:
      return loadView(state, action)
    case types.LOAD_VIEW_EDITOR_SUCCESS:
      return loadViewSuccess(state, action)
    case types.LOAD_VIEW_EDITOR_FAIL:
      return loadViewFail(state, action)
    case types.LOAD_ALL_VIEW_EDITOR:
      return loadViews(state)
    case types.LOAD_ALL_VIEW_EDITOR_SUCCESS:
      return loadViewsSuccess(state, action)
    case types.LOAD_ALL_VIEW_EDITOR_FAIL:
      return loadViewsFail(state, action)
    case types.SAVE_VIEW_EDITOR:
      return saveViewEditor(state)
    case types.SAVE_VIEW_EDITOR_SUCCESS:
      return saveViewEditorSuccess(state, action)
    case types.DELETE_VIEW_EDITOR:
      return deleteViewEditor(state, action)
    case types.DELETE_VIEW_EDITOR_SUCCESS:
      return deleteViewEditorSuccess(state, action)
    case types.DELETE_VIEW_EDITOR_FAIL:
      return deleteViewEditorFail(state, action)
    case GO_TO:
      return unselectView(state)
    default:
      return state
  }
}

export default viewEditor
