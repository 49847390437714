import * as types from '../constants/action-types/view'

export function loadView (payload) {
  return { type: types.LOAD_VIEW, payload }
}

export function loadViewSuccess (payload) {
  return { type: types.LOAD_VIEW_SUCCESS, payload }
}

export function loadViewFail (payload) {
  return { type: types.LOAD_VIEW_FAIL, payload }
}

export function loadViewCanceled (payload) {
  return { type: types.LOAD_VIEW_CANCELED, payload }
}

export function selectSubView (payload) {
  return { type: types.SELECT_SUB_VIEW, payload }
}

export function unselectSubView (payload) {
  return { type: types.UNSELECT_SUB_VIEW, payload }
}

export function removeView (payload) {
  return { type: types.REMOVE_VIEW, payload }
}
