import * as types from '../constants/action-types/filter-editor'
import { GO_TO } from '../constants/action-types/path'

function initialState () {
  return {
    loading: false,
    filters: [],
    filterUUID: null,
    filterDetail: null,
    error: null
  }
}

function getFilters (state) {
  return {
    ...state,
    loading: true,
    filterUUID: null,
    error: null
  }
}

function getFiltersSuccess (state, { payload }) {
  return {
    ...state,
    loading: false,
    filters: payload,
    error: null
  }
}

function getFilter (state) {
  return {
    ...state,
    loading: true,
    error: null
  }
}

function getFilterSuccess (state, { payload }) {
  return {
    ...state,
    loading: false,
    filterDetail: payload,
    filterUUID: payload.uuid,
    error: null
  }
}

function addFilter (state) {
  return {
    ...state,
    loading: true,
    error: null
  }
}

function addFilterSuccess (state, { payload }) {
  return {
    ...state,
    loading: false,
    filters: state.filters.concat(payload),
    filterUUID: null,
    filterDetail: null,
    error: null
  }
}

function editFilter (state) {
  return {
    ...state,
    loading: true,
    error: null
  }
}

function editFilterSuccess (state, { payload }) {
  return {
    ...state,
    loading: false,
    filters: state.filters.map(filter => filter.uuid === payload.uuid ? payload : filter),
    filterUUID: null,
    filterDetail: null,
    error: null
  }
}

function deleteFilter (state) {
  return {
    ...state,
    loading: true,
    error: null
  }
}

function deleteFilterSuccess (state, { payload }) {
  return {
    ...state,
    loading: false,
    filters: state.filters.filter((filter) => filter.uuid !== payload),
    error: null
  }
}

function unselectFilter (state) {
  return {
    ...state,
    filterUUID: null,
    filterDetail: null
  }
}

function filtersFail (state, { payload }) {
  return {
    ...state,
    loading: false,
    filterDetail: payload.filterDetail || null,
    error: payload.error || payload
  }
}

function setFilterDetail (state, { payload }) {
  return {
    ...state,
    filterUUID: payload,
    filterDetail: state.filters.find((filter) => filter.uuid === payload) || {},
    error: null
  }
}

export default function filterEditor (state = initialState(), action) {
  switch (action.type) {
    case types.GET_FILTERS:
      return getFilters(state, action)
    case types.GET_FILTERS_SUCCESS:
      return getFiltersSuccess(state, action)
    case types.GET_FILTER:
      return getFilter(state)
    case types.GET_FILTER_SUCCESS:
      return getFilterSuccess(state, action)
    case types.ADD_FILTER:
      return addFilter(state, action)
    case types.ADD_FILTER_SUCCESS:
      return addFilterSuccess(state, action)
    case types.EDIT_FILTER:
      return editFilter(state, action)
    case types.EDIT_FILTER_SUCCESS:
      return editFilterSuccess(state, action)
    case types.DELETE_FILTER:
      return deleteFilter(state, action)
    case types.DELETE_FILTER_SUCCESS:
      return deleteFilterSuccess(state, action)
    case types.FILTERS_FAIL:
      return filtersFail(state, action)
    case GO_TO:
      return unselectFilter(state)
    case types.SET_FILTER_DETAIL:
      return setFilterDetail(state, action)
    default:
      return state
  }
}
