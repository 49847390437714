import * as types from '../constants/action-types/me'

export function loadMe () {
  return { type: types.LOAD_ME }
}

export function loadMeSuccess (payload) {
  return { type: types.LOAD_ME_SUCCESS, payload }
}

export function loadMeFail (payload) {
  return { type: types.LOAD_ME_FAIL, payload }
}
