export const OPEN_LEFT_MENU = 'OPEN_LEFT_MENU'
export const CLOSE_LEFT_MENU = 'CLOSE_LEFT_MENU'
export const OPEN_SETTINGS_MENU = 'OPEN_SETTINGS_MENU'
export const CLOSE_SETTINGS_MENU = 'CLOSE_SETTINGS_MENU'
export const OPEN_HELP_MENU = 'OPEN_HELP_MENU'
export const CLOSE_HELP_MENU = 'CLOSE_HELP_MENU'
export const TOGGLE_LEFT_MENU_CATEGORY = 'TOGGLE_LEFT_MENU_CATEGORY'
export const CHECK_CONTENT_MIGRATION = 'CHECK_CONTENT_MIGRATION'
export const CHECK_CONTENT_MIGRATION_SUCCESS = 'CHECK_CONTENT_MIGRATION_SUCCESS'
export const CHECK_CONTENT_MIGRATION_FAIL = 'CHECK_CONTENT_MIGRATION_FAIL'
