import api from '../handlers/api'
import * as actions from '../actions/filter'
import { loadView } from './view'
import { addNotification } from '../actions/notification'
import { getCurrentOrganizationUUID } from '../selectors/organization'
import { getStartDate, getEndDate, getCategoryDashboardName } from '../selectors/dashboard'
import { getActivePanelFilters } from '../selectors/filter'
import { MessageType } from '../constants'

export function applyFilters ({ event, closePanel = false }) {
  return async (dispatch, getState) => {
    try {
      event.previousFilters = getActivePanelFilters(getState())
      dispatch(actions.applyFilters(closePanel))
      const state = getState()
      event.currentFilters = getActivePanelFilters(state)
      event.dashboard = getCategoryDashboardName(state)
      window.mixpanel.trackFilters(event)
      localStorage.setItem('selectedFilterGroup', JSON.stringify(state.filter.selectedFilterGroup))
      const promises = Object.keys(state.view).map(viewId => dispatch(loadView(viewId)))
      await Promise.all(promises)
    } catch (error) {
      dispatch(addNotification({
        message: 'Failed to apply the filters',
        type: MessageType.ERROR
      }))
    }
  }
}

export function clearFilters () {
  return async (dispatch, getState) => {
    try {
      dispatch(actions.clearFilters())
      const state = getState()
      window.mixpanel.trackFilters({ type: 'Clear', dashboard: getCategoryDashboardName(state) })

      const promises = Object.keys(state.view).map(viewId => dispatch(loadView(viewId)))
      await Promise.all(promises)
    } catch (error) {
      dispatch(addNotification({
        message: 'Failed to clear the filters',
        type: MessageType.ERROR
      }))
    }
  }
}

export function loadFilters () {
  return async (dispatch, getState) => {
    try {
      dispatch(actions.loadFilters())
      const state = getState()

      const params = {
        start: getStartDate(state),
        end: getEndDate(state)
      }

      const queryString = new URLSearchParams(params).toString()
      const response = await api.get(`/filter-values?${queryString}`)
      dispatch(actions.loadFiltersSuccess(response.data))
      dispatch(actions.setStandaloneFilterValues())
      dispatch(actions.applyFilters())
    } catch (error) {
      dispatch(actions.loadFiltersFail(error.message))
      dispatch(addNotification({
        message: 'Error loading filters',
        type: MessageType.ERROR
      }))
    }
  }
}

export function loadFilter (filterUUID) {
  return async (dispatch, getState) => {
    try {
      dispatch(actions.loadFilter(filterUUID))
      const state = getState()

      const params = {
        start: getStartDate(state),
        end: getEndDate(state)
      }

      const queryString = new URLSearchParams(params).toString()
      const response = await api.get(`/filter-values/${filterUUID}?${queryString}`)
      dispatch(actions.loadFilterSuccess(response.data))

      const filter = getState(state).filter.filters.find(filter => filter.uuid === filterUUID)
      dispatch(actions.setFilterValue({ filterUUID, value: filter.selectedValues[0] }))
      dispatch(actions.applyFilters())
    } catch (error) {
      dispatch(actions.loadFilterFail({ error: error.message, filterUUID }))
      dispatch(addNotification({
        message: `Error loading filter '${filterUUID}'`,
        type: MessageType.ERROR
      }))
    }
  }
}

export function saveOrganizationFilter (filterUUID, body) {
  return async (dispatch, getState) => {
    try {
      dispatch(actions.saveOrganizationFilter())
      const state = getState()
      const organizationUUID = getCurrentOrganizationUUID(state)

      await api.patch(`/organizations/${organizationUUID}/filters/${filterUUID}`, body)
      dispatch(actions.saveOrganizationFilterSuccess({ filterUUID, filter: body }))
    } catch (error) {
      dispatch(actions.saveOrganizationFilterFail(error.message))
      dispatch(addNotification({
        message: `Error saving filter '${filterUUID}'`,
        type: MessageType.ERROR
      }))
    }
  }
}
