import * as types from '../constants/action-types/school-admin-dashboard'
import { groupBy } from 'lodash'

const initialState = () => ({
  allDashboards: {},
  modified: [],
  categories: [],
  savingDashboard: {},
  loading: false,
  saving: false,
  error: ''
})

const loadAdminDashboards = (state, { payload }) => ({
  ...state,
  loading: true
})

const loadAdminDashboardsSuccess = (state, { payload }) => ({
  ...state,
  allDashboards: groupBy(
    payload.dashboards,
    'category'
  ),
  categories: payload.categories.sort((a, b) => a.order - b.order),
  loading: false
})

const loadAdminDashboardsFail = (state, { error }) => ({
  ...state,
  error: error,
  loading: false
})

const loadAdminDashboardsCanceled = (state) => ({
  ...state,
  loading: false
})

const newDashboardOrder = (state, { payload }) => {
  const newArray = [...payload.modified]
  const oldArray = [...state.modified]
  const modified = oldArray.length === 0 ? newArray : oldArray.map(obj => newArray.find(o => o.id === obj.id) || obj)

  return {
    ...state,
    allDashboards: {
      ...state.allDashboards,
      [payload.categoryUUID]: payload.dashboards
    },
    modified: [...new Set([...modified, ...newArray])]
  }
}

const publishDashboard = (state, { payload }) => {
  const allDashboards = state.allDashboards[payload.categoryUUID]
  const dashboards = allDashboards.map(dashboard => {
    if (dashboard.id !== payload.modified.id) return dashboard
    return {
      ...dashboard,
      publish: payload.modified.publish
    }
  })
  const newArray = { ...payload.modified }
  const oldArray = [...state.modified]
  const modified = oldArray.length === 0 ? [newArray] : oldArray.map(obj => newArray.id === obj.id ? newArray : obj)

  return {
    ...state,
    allDashboards: {
      ...state.allDashboards,
      [payload.categoryUUID]: dashboards
    },
    modified: modified
  }
}

const saveModifiedDashboards = (state) => ({
  ...state,
  saving: true
})

const saveModifiedDashboardsSuccess = (state) => ({
  ...state,
  saving: false,
  modified: []

})

const saveModifiedDashboardsFail = (state, { error }) => ({
  ...state,
  error: error,
  saving: false
})

const schoolAdminDashboard = (state = initialState(), action) => {
  switch (action.type) {
    case types.LOAD_ADMIN_DASHBOARDS:
      return loadAdminDashboards(state, action)
    case types.LOAD_ADMIN_DASHBOARDS_SUCCESS:
      return loadAdminDashboardsSuccess(state, action)
    case types.LOAD_ADMIN_DASHBOARDS_FAIL:
      return loadAdminDashboardsFail(state, action.payload)
    case types.LOAD_ADMIN_DASHBOARDS_CANCELED:
      return loadAdminDashboardsCanceled(state, action)
    case types.NEW_DASHBOARD_ORDER:
      return newDashboardOrder(state, action)
    case types.PUBLISH_DASHBOARD:
      return publishDashboard(state, action)
    case types.SAVE_MODIFIED_DASHBOARDS:
      return saveModifiedDashboards(state, action)
    case types.SAVE_MODIFIED_DASHBOARDS_SUCCESS:
      return saveModifiedDashboardsSuccess(state, action)
    case types.SAVE_MODIFIED_DASHBOARDS_FAIL:
      return saveModifiedDashboardsFail(state, action)
    default:
      return state
  }
}

export default schoolAdminDashboard
