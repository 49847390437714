export default {
  tooltip: {
    backgroundColor: '#fff',
    color: '#3C3C3C',
    fontSize: 14,
    margin: '10px 0 !important',
    padding: '10px 15px',
    boxShadow: `0px 2px 4px -1px rgba(0, 0, 0, 0.2),
                0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                0px 1px 10px 0px rgba(0, 0, 0, 0.12)`
  }
}
