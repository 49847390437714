import * as types from '../constants/action-types/data-field-mapping'

function initialState () {
  return {
    loading: false,
    saving: false,
    editorOpen: false,
    dataFieldMapping: null,
    selectedDataField: null,
    changedDataField: [],
    isStatus: 0,
    error: null
  }
}

function getDataFieldMapping (state) {
  return {
    ...state,
    loading: true
  }
}

function getDataFieldMappingSuccess (state, { payload }) {
  const isUpdated = [].concat.apply([], Object.keys(payload).map(k => payload[k])).some(el => el.custom_field_status === 'Updated')
  const isPending = [].concat.apply([], Object.keys(payload).map(k => payload[k])).some(el => el.custom_field_status === 'Pending')
  const isApplied = [].concat.apply([], Object.keys(payload).map(k => payload[k])).some(el => el.custom_field_status === 'Applied')
  return {
    ...state,
    dataFieldMapping: payload,
    loading: false,
    isStatus: isUpdated === true ? 1 : isPending === true ? 2 : isApplied === true ? 3 : 0,
    error: null
  }
}

function getDataFieldMappingFail (state, { payload }) {
  return {
    ...state,
    loading: false,
    error: payload
  }
}

function saveDataFieldMapping (state) {
  return {
    ...state,
    saving: true,
    loading: true
  }
}

function saveDataFieldMappingSuccess (state) {
  return {
    ...state,
    editorOpen: false,
    changedDataField: [],
    selectedDataField: null,
    loading: false,
    saving: false
  }
}

function saveDataFieldMappingFail (state, { payload }) {
  return {
    ...state,
    saving: false,
    editorOpen: false,
    changedDataField: [],
    selectedDataField: null,
    loading: false,
    error: payload
  }
}

function openDataFieldEditor (state, { payload }) {
  return {
    ...state,
    editorOpen: true,
    changedDataField: [],
    selectedDataField: {
      name: payload.name,
      data: payload.data
    }
  }
}

function closeDataFieldEditor (state, { payload }) {
  return {
    ...state,
    editorOpen: false,
    selectedDataField: null,
    changedDataField: []
  }
}

function changeDataFieldMapping (state, { payload: { el, item, term, index } }) {
  const fieldData = state && state.changedDataField && [...state.changedDataField]
  const selectedDataField = state && { ...state.selectedDataField }
  selectedDataField && selectedDataField.data && selectedDataField.data.map((selectedField) => {
    selectedField.fields.map((fieldItem) => {
      if (fieldItem.value !== 'Not Used') {
        let selectedValue = el.value.replace('(default)', '').trim()
        let prevVal = item.previous_field_name.replace('(default)', '').trim()
        let defVal = fieldItem.value.replace('(default)', '').trim()
        if (defVal === prevVal && selectedField.object_name === item.object_name) {
          fieldItem['disabled'] = false
          fieldItem['type'] = 'unmapped values'
        }
        if (defVal === selectedValue && selectedField.object_name === item.object_name) {
          fieldItem['disabled'] = true
          fieldItem['type'] = 'mapped values'
        }
      }
      return true
    })
    return true
  })
  const data = {
    field_map_standard_id: item && item.field_map_standard_id,
    custom_field_name: el && el.value,
    custom_field_status: 'Updated'
  }
  if (item) {
    item['previous_field_name'] = el && el.value
    if (item.custom_field_name !== null) {
      data.selected_field_name = item.custom_field_name
    } else {
      data.selected_field_name = item.default_value
    }
  }
  if (item && item.custom_field_id !== null) {
    data.custom_field_id = item.custom_field_id
  }
  if (fieldData) {
    if (fieldData.length === 0) {
      fieldData.push(data)
    } else {
      const index = fieldData.findIndex((ol) => ol.field_map_standard_id === item.field_map_standard_id)
      if (index === -1) {
        fieldData.push(data)
      } else {
        const index1 = fieldData.findIndex((ol) => ol.selected_field_name === el.value.replace('(default)', '').trim())
        if (index1 === -1) {
          fieldData[index] = data
        } else {
          fieldData.splice(index1, 1)
        }
      }
    }
  }
  return {
    ...state,
    changedDataField: fieldData
  }
}

export default function mapping (state = initialState(), action) {
  switch (action.type) {
    case types.GET_DATA_FIELD_MAPPING:
      return getDataFieldMapping(state)
    case types.GET_DATA_FIELD_MAPPING_SUCCESS:
      return getDataFieldMappingSuccess(state, action)
    case types.GET_DATA_FIELD_MAPPING_FAIL:
      return getDataFieldMappingFail(state, action)
    case types.SAVE_DATA_FIELD_MAPPING:
      return saveDataFieldMapping(state)
    case types.SAVE_DATA_FIELD_MAPPING_SUCCESS:
      return saveDataFieldMappingSuccess(state)
    case types.SAVE_DATA_FIELD_MAPPING_FAIL:
      return saveDataFieldMappingFail(state, action)
    case types.OPEN_DATA_FIELD_EDITOR:
      return openDataFieldEditor(state, action)
    case types.CLOSE_DATA_FIELD_EDITOR:
      return closeDataFieldEditor(state, action)
    case types.CHANGE_DATA_FIELD_MAPPING:
      return changeDataFieldMapping(state, action)
    default:
      return state
  }
}
