import * as Qs from 'qs'
import * as actions from '../actions/view'
import api from '../handlers/api'
import { addNotification } from '../actions/notification'
import { getStartDate, getEndDate, getCategoryDashboardName } from '../selectors/dashboard'
import { getActiveViewFilters } from '../selectors/view'
import { ErrorCode, MessageType } from '../constants'
import Paths, { getActivePath } from '../components/routes/Paths'

export function loadView (viewId, temp = false) {
  return async (dispatch, getState) => {
    try {
      dispatch(cancelLoadingView(viewId))
      dispatch(actions.loadView({ viewId, temp }))
      const state = getState()
      const view = state.view[viewId]
      const activePath = getActivePath(Paths, state.router.location.pathname)

      const params = {
        start: getStartDate(state),
        end: getEndDate(state),
        mode: activePath === Paths.VIEW_PREVIEW ? 'preview' : 'dashboard',
        ...getActiveViewFilters(state, viewId)
      }
      if (localStorage.selectedFilterGroup) {
        params.filter_groups = localStorage.selectedFilterGroup
      }

      const queryString = Qs.stringify(params, { arrayFormat: 'brackets' })
      const cancelToken = view.source ? view.source.token : null
      const response = await api.post(`/views/transform/${viewId}`, params)

      const data = 'tag' in response.data ? { data: response.data } : response.data
      dispatch(actions.loadViewSuccess({ ...data, id: viewId }))
    } catch (error) {
      if (error.message === ErrorCode.E0000) return

      dispatch(actions.loadViewFail({ error, id: viewId }))
      dispatch(addNotification({
        message: `Error loading view '${viewId}'`,
        type: MessageType.ERROR
      }))
    }
  }
}

export function cancelLoadingView (id) {
  return (dispatch, getState) => {
    const state = getState()
    const view = state.view[id]

    if (view && view.loading && view.source) {
      view.source.cancel(ErrorCode.E0000)
      dispatch(actions.loadViewCanceled(id))
    }
  }
}

export function downloadCSV (viewId) {
  return async (dispatch, getState) => {
    const state = getState()
    const view = state.view[viewId]
    const categoryDashboardName = getCategoryDashboardName(state)

    try {
      const params = {
        ...getActiveViewFilters(state, viewId)
      }

      delete params.counselor_v2
      delete params.ethnicity_equity
      delete params.gender_equity
      delete params.maincampus
      delete params.event_name_v2

      if (categoryDashboardName === 'Events - Overview' || categoryDashboardName === 'Events - Event Performance') {
        params.start = getStartDate(state)
        params.end = getEndDate(state)
      }
      const queryString = Qs.stringify(params, { arrayFormat: 'brackets' })
      const response = await api.get(`/views/download_csv/${viewId}?${queryString}`)
      const fileName = response.headers['content-disposition'].match(/attachment;filename="(.+)"/)[1]
      const link = document.createElement('a')
      const blob = new Blob([response.data], { type: 'text/csv' })
      link.href = URL.createObjectURL(blob)
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.mixpanel.track('CSV download', { View: view && view.name, Dashboard: categoryDashboardName })
    } catch (error) {
      dispatch(addNotification({
        message: `Error downloading CSV for view '${viewId}'`,
        type: MessageType.ERROR
      }))
      window.mixpanel.track('CSV download error', { View: view && view.name, Dashboard: categoryDashboardName })
    }
  }
}
