import * as types from '../constants/action-types/logs'

function initialState () {
  return {
    loading: false,
    logs: [],
    error: null
  }
}

function getLogs (state) {
  return {
    ...state,
    loading: true
  }
}

function getLogsSuccess (state, { payload }) {
  return {
    ...state,
    logs: payload,
    loading: false,
    error: null
  }
}

function getLogsFail (state, { payload }) {
  return {
    ...state,
    loading: false,
    error: payload
  }
}

function getLogsFilter (state) {
  return {
    ...state,
    loading: true
  }
}

function getLogsFilterSuccess (state, { payload }) {
  return {
    ...state,
    logs: payload,
    loading: false,
    error: null
  }
}

function getLogsFilterFail (state, { payload }) {
  return {
    ...state,
    loading: false,
    error: payload
  }
}

export default function logs (state = initialState(), action) {
  switch (action.type) {
    case types.LOGS:
      return getLogs(state)
    case types.LOGS_SUCCESS:
      return getLogsSuccess(state, action)
    case types.LOGS_FAIL:
      return getLogsFail(state, action)
    case types.LOGS_FILTER:
      return getLogsFilter(state)
    case types.LOGS_FILTER_SUCCESS:
      return getLogsFilterSuccess(state, action)
    case types.LOGS_FILTER_FAIL:
      return getLogsFilterFail(state, action)
    default:
      return state
  }
}
