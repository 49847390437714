import React from 'react'
import './LoadingDots.css'

const LoadingDots = () => {
  return (
    <div className='loading-dots'>
      <div data-testid="loading-container" className='loading-dots__container'>
        <div className='loading-dots__item' />
        <div className='loading-dots__item' />
        <div className='loading-dots__item' />
        <div className='loading-dots__item' />
        <div className='loading-dots__item' />
        <div className='loading-dots__item' />
        <div className='loading-dots__item' />
        <div className='loading-dots__item' />
        <div className='loading-dots__item' />
      </div>
    </div>
  )
}

export default LoadingDots
