import * as types from '../constants/action-types/category'

const initialState = () => ({
  all: [],
  loading: false,
  error: ''
})

const loadCategories = (state) => ({
  ...state,
  loading: true
})

const loadCategoriesSuccess = (state, { payload }) => ({
  ...state,
  all: payload.categories,
  loading: false
})

const loadCategoriesFail = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: false
})

const saveCategories = (state) => ({
  ...state,
  loading: true
})

const saveCategoriesSuccess = (state, { payload }) => ({
  ...state,
  all: payload.categories.sort((a, b) => a.order - b.order),
  loading: false
})

const saveCategoriesFail = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: false
})

const category = (state = initialState(), action) => {
  switch (action.type) {
    case types.LOAD_CATEGORIES:
      return loadCategories(state, action)
    case types.LOAD_CATEGORIES_SUCCESS:
      return loadCategoriesSuccess(state, action)
    case types.LOAD_CATEGORIES_FAIL:
      return loadCategoriesFail(state, action)
    case types.SAVE_CATEGORIES:
      return saveCategories(state, action)
    case types.SAVE_CATEGORIES_SUCCESS:
      return saveCategoriesSuccess(state, action)
    case types.SAVE_CATEGORIES_FAIL:
      return saveCategoriesFail(state, action)
    default:
      return state
  }
}

export default category
