import mixpanel from 'mixpanel-browser'

class Mixpanel {
  constructor () {
    this.initialized = false
  }

  init (user) {
    if (process.env.REACT_APP_MIXPANEL_ENABLED !== 'true') return

    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)
    mixpanel.identify(user.id)

    mixpanel.people.set({
      $email: user.email,
      $first_name: user.first_name,
      $last_name: user.last_name,
      $name: `${user.first_name} ${user.last_name}`,
      Roles: user.user_roles.join(', '),
      Organization: user.organization.name,
      'Organization ID': user.organization.uuid
    })

    this.initialized = true
  }

  track (...args) {
    if (this.initialized) {
      mixpanel.track(...args)
    }
  }

  trackFilters (event) {
    const formatFilters = (filters) => filters
      .map(filter => `${filter.name} (${filter.selectedValues.join(', ')})`)
      .join(', ')

    const properties = {
      Type: event.type,
      Dashboard: event.dashboard
    }

    switch (event.type) {
      case 'Panel':
        properties.From = formatFilters(event.previousFilters)
        properties.To = formatFilters(event.currentFilters)
        break
      case 'Standalone':
      case 'Table':
        properties.Filter = event.filter.name
        properties.From = event.filter.selectedValues[0]
        properties.To = event.value
        break
      case 'Header':
        properties.Filter = event.filter.name
        properties.From = event.filter.selectedValues.join(', ')
        properties.To = event.filter.selectedValues.filter(value => value !== event.value).join(', ')
        break
      default:
        break
    }

    this.track('Filters change', properties)
  }
}

const instance = new Mixpanel()

export default instance
