const common = {
  color: '#fff',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}

export default {
  root: {
    padding: 0
  },
  primary: {
    ...common
  },
  secondary: {
    ...common,
    fontSize: '0.8em'
  }
}
