export function persistFilters (state) {
  localStorage.activeFilters = JSON.stringify(state.activeFilters)
  return state
}

export function retrieveFilters (state) {
  const activeFilters = localStorage.activeFilters ? JSON.parse(localStorage.activeFilters) : {}
  return {
    ...state,
    activeFilters,
    filters: state.filters.map(filter => ({
      ...filter,
      selectedValues: activeFilters[filter.uuid] || []
    })),
    selectedFilterGroup: localStorage.selectedFilterGroup ? JSON.parse(localStorage.selectedFilterGroup) : []
  }
}

export function persistDateRange (state) {
  localStorage.dateRangeStart = state.dateRange.startDate.valueOf()
  localStorage.dateRangeEnd = state.dateRange.endDate.valueOf()
  return state
}

export function retrieveDateRange (state) {
  const dateRange = localStorage.dateRangeStart && localStorage.dateRangeEnd ? ({
    startDate: new Date(Number(localStorage.dateRangeStart)),
    endDate: new Date(Number(localStorage.dateRangeEnd))
  }) : state.dateRange

  return {
    ...state,
    dateRange,
    changedDateRange: dateRange
  }
}
