import * as types from '../constants/action-types/school-admin-dashboard'

export function loadAdminDashboards () {
  return { type: types.LOAD_ADMIN_DASHBOARDS }
}

export function loadAdminDashboardsSuccess (payload) {
  return { type: types.LOAD_ADMIN_DASHBOARDS_SUCCESS, payload }
}

export function loadAdminDashboardsFail (payload) {
  return { type: types.LOAD_ADMIN_DASHBOARDS_FAIL, payload }
}

export function newDashboardOrder (payload) {
  return { type: types.NEW_DASHBOARD_ORDER, payload }
}

export function publishDashboard (payload) {
  return { type: types.PUBLISH_DASHBOARD, payload }
}

export function saveModifiedDashboards (payload) {
  return { type: types.SAVE_MODIFIED_DASHBOARDS, payload }
}

export function saveModifiedDashboardsSuccess () {
  return { type: types.SAVE_MODIFIED_DASHBOARDS_SUCCESS }
}

export function saveModifiedDashboardsFail (payload) {
  return { type: types.SAVE_MODIFIED_DASHBOARDS_FAIL, payload }
}
