import * as types from '../constants/action-types/target'

function initialState () {
  return {
    goals: [],
    targets: [],
    totals: [],
    changedTargets: [],
    changedTotals: [],
    searchText: '',
    loading: false,
    saving: false,
    deleting: false,
    editorOpen: false,
    areNew: false,
    selectedTerm: null,
    created: null,
    error: null
  }
}

function getTargets (state) {
  return {
    ...state,
    loading: true
  }
}

function getTargetsSuccess (state, { payload: { goals, targets, totals } }) {
  return {
    ...state,
    goals,
    targets,
    totals,
    loading: false
  }
}

function getTargetsFail (state, { payload: error }) {
  return {
    ...state,
    error,
    loading: false
  }
}

function createTargets (state) {
  return {
    ...state,
    changedTargets: [],
    changedTotals: [],
    editorOpen: true,
    areNew: true,
    selectedTerm: null,
    clonedTerm: null
  }
}

function emptyChangedTargets (state, { payload }) {
  const el = state.changedTargets.filter((item) => item.filter_uuid !== payload.uuid)
  return {
    ...state,
    changedTargets: el
  }
}

function editTargets (state, { payload: term }) {
  return {
    ...state,
    changedTargets: state.targets.filter(target => target.term === term),
    changedTotals: state.totals.filter(total => total.term === term),
    editorOpen: true,
    areNew: false,
    selectedTerm: term,
    clonedTerm: null
  }
}

function cloneTargets (state, { payload: term }) {
  return {
    ...state,
    changedTargets: state.targets.filter(target => target.term === term).map(({ uuid, ...target }) => target),
    changedTotals: state.totals.filter(total => total.term === term).map(({ uuid, ...target }) => target),
    editorOpen: true,
    areNew: true,
    selectedTerm: null,
    clonedTerm: term
  }
}

function cancelTargets (state) {
  return {
    ...state,
    editorOpen: false
  }
}

function hideCreatedTargetsMessage (state) {
  return {
    ...state,
    created: null
  }
}

function setTerm (state, { payload: { value: term } }) {
  return {
    ...state,
    selectedTerm: term
  }
}

function setTotal (state, { payload: total }) {
  const changedTotals = state.changedTotals.filter(changedTotal => changedTotal.goal_uuid !== total.goal_uuid)

  if (total.value) {
    changedTotals.push(total)
  }

  return {
    ...state,
    changedTotals
  }
}

function setTarget (state, { payload: target }) {
  const changedTargets = state.changedTargets.filter(changedTarget => !(
    changedTarget.goal_uuid === target.goal_uuid &&
    changedTarget.filter_uuid === target.filter_uuid &&
    changedTarget.filter_value === target.filter_value
  ))

  if (target.value) {
    changedTargets.push(target)
  }

  return {
    ...state,
    changedTargets
  }
}

function searchTargets (state, { payload: searchText }) {
  return {
    ...state,
    searchText
  }
}

function saveTargets (state) {
  return {
    ...state,
    saving: true
  }
}

function saveTargetsSuccess (state, { payload: { term, targets, totals, areNew } }) {
  let newTargets
  let newTotals

  if (areNew) {
    newTargets = [
      ...state.targets,
      ...targets
    ]

    newTotals = [
      ...state.totals,
      ...totals
    ]
  } else {
    newTargets = [
      ...state.targets.filter(target => target.term !== term),
      ...targets
    ]

    newTotals = [
      ...state.totals.filter(total => total.term !== term),
      ...totals
    ]
  }

  return {
    ...state,
    saving: false,
    editorOpen: false,
    targets: newTargets,
    totals: newTotals,
    created: areNew ? { term, targets: targets.length + totals.length } : null
  }
}

function saveTargetsFail (state, { payload: error }) {
  return {
    ...state,
    error,
    saving: false
  }
}

function deleteTargets (state) {
  return {
    ...state,
    deleting: true
  }
}

function deleteTargetsSuccess (state, { payload: { deletedTargets, deletedTotals } }) {
  return {
    ...state,
    deleting: false,
    targets: state.targets.filter(target => !deletedTargets.includes(target.uuid)),
    totals: state.totals.filter(total => !deletedTotals.includes(total.uuid))
  }
}

function deleteTargetsFail (state, { payload: error }) {
  return {
    ...state,
    error,
    deleting: false
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case types.GET_TARGETS:
      return getTargets(state)
    case types.GET_TARGETS_SUCCESS:
      return getTargetsSuccess(state, action)
    case types.GET_TARGETS_FAIL:
      return getTargetsFail(state, action)
    case types.CREATE_TARGETS:
      return createTargets(state)
    case types.EMPTY_CHANGED_TARGET:
      return emptyChangedTargets(state, action)
    case types.EDIT_TARGETS:
      return editTargets(state, action)
    case types.CLONE_TARGETS:
      return cloneTargets(state, action)
    case types.CANCEL_TARGETS:
      return cancelTargets(state)
    case types.HIDE_CREATED_TARGETS_MESSAGE:
      return hideCreatedTargetsMessage(state)
    case types.SET_TERM:
      return setTerm(state, action)
    case types.SET_TOTAL:
      return setTotal(state, action)
    case types.SET_TARGET:
      return setTarget(state, action)
    case types.SEARCH_TARGETS:
      return searchTargets(state, action)
    case types.SAVE_TARGETS:
      return saveTargets(state)
    case types.SAVE_TARGETS_SUCCESS:
      return saveTargetsSuccess(state, action)
    case types.SAVE_TARGETS_FAIL:
      return saveTargetsFail(state, action)
    case types.DELETE_TARGETS:
      return deleteTargets(state)
    case types.DELETE_TARGETS_SUCCESS:
      return deleteTargetsSuccess(state, action)
    case types.DELETE_TARGETS_FAIL:
      return deleteTargetsFail(state, action)
    default:
      return state
  }
}
