import * as types from '../constants/action-types/content-migration'

function initialState () {
  return {
    loading: false,
    contentMigrationData: [],
    error: null,
    isSaving: false,
    isSavingError: false
  }
}

function getContentMigration (state) {
  return {
    ...state,
    loading: true
  }
}

function getContentMigrationSuccess (state, { payload }) {
  return {
    ...state,
    contentMigrationData: payload,
    loading: false,
    error: null
  }
}

function getContentMigrationFail (state, { payload }) {
  return {
    ...state,
    loading: false,
    error: payload
  }
}

function exportContentMigration (state) {
  return {
    ...state,
    isSaving: true,
    isSavingError: false
  }
}

function exportContentMigrationSuccess (state) {
  return {
    ...state,
    isSaving: false,
    isSavingError: false
  }
}

function exportContentMigrationFail (state) {
  return {
    ...state,
    isSaving: false,
    isSavingError: true
  }
}

export default function contentMigration (state = initialState(), action) {
  switch (action.type) {
    case types.CONTENT_MIGRATION:
      return getContentMigration(state)
    case types.CONTENT_MIGRATION_SUCCESS:
      return getContentMigrationSuccess(state, action)
    case types.CONTENT_MIGRATION_FAIL:
      return getContentMigrationFail(state, action)
    case types.EXPORT_CONTENT_MIGRATION:
      return exportContentMigration(state)
    case types.EXPORT_CONTENT_MIGRATION_SUCCESS:
      return exportContentMigrationSuccess(state)
    case types.EXPORT_CONTENT_MIGRATION_FAIL:
      return exportContentMigrationFail(state)
    default:
      return state
  }
}
