import axios from 'axios'
import { get } from 'lodash'

const isMocked = process.env.REACT_APP_MOCK === 'true'
const baseURL = isMocked ? process.env.REACT_APP_MOCK_URL : process.env.REACT_APP_BASE_URL
const timeout = Number(process.env.REACT_APP_API_TIMEOUT) * 1000

const instance = axios.create({
  baseURL,
  timeout,
  withCredentials: !isMocked
})

instance.interceptors.response.use(null, (error) => {
  if (get(error, 'response.status') === 401) {
    instance.redirectToLogin(window.location.href)
  }
  return Promise.reject(error)
})

instance.setHeader = setHeader
instance.removeHeader = removeHeader
instance.redirectToLogin = redirectToLogin

if (process.env.REACT_APP_MOCK === 'true') {
  instance.setHeader('X-Api-Key', process.env.REACT_APP_MOCK_API_KEY)
}

if (process.env.NODE_ENV === 'test') {
  instance.defaults.adapter = 'http'//require('axios/lib/adapters/http')
}

export { baseURL }
export default instance

function setHeader (name, value) {
  this.defaults.headers.common[name] = value
}

function removeHeader (name) {
  delete this.defaults.headers.common[name]
}

function redirectToLogin (redirectURL) {
  const callbackURL = `${process.env.REACT_APP_BASE_URL}/targetx/callback?redirectURL=${encodeURIComponent(redirectURL)}`
  const shortName = localStorage.getItem('txOrgShortName')
  const loginURL = shortName ? `${process.env.REACT_APP_LOGIN_URL}/${shortName}/sign-in` : `${process.env.REACT_APP_LOGIN_URL}/sign-in`
  window.localStorage.clear()
  window.location.href = `${loginURL}?redirectURL=${encodeURIComponent(callbackURL)}`
}
