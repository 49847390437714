export const GET_TARGETS = 'GET_TARGETS'
export const GET_TARGETS_SUCCESS = 'GET_TARGETS_SUCCESS'
export const GET_TARGETS_FAIL = 'GET_TARGETS_FAIL'
export const CREATE_TARGETS = 'CREATE_TARGETS'
export const EDIT_TARGETS = 'EDIT_TARGETS'
export const CLONE_TARGETS = 'CLONE_TARGETS'
export const CANCEL_TARGETS = 'CANCEL_TARGETS'
export const HIDE_CREATED_TARGETS_MESSAGE = 'HIDE_CREATED_TARGETS_MESSAGE'
export const SET_TERM = 'SET_TERM'
export const SET_TOTAL = 'SET_TOTAL'
export const SET_TARGET = 'SET_TARGET'
export const SEARCH_TARGETS = 'SEARCH_TARGETS'
export const SAVE_TARGETS = 'SAVE_TARGETS'
export const SAVE_TARGETS_SUCCESS = 'SAVE_TARGETS_SUCCESS'
export const SAVE_TARGETS_FAIL = 'SAVE_TARGETS_FAIL'
export const DELETE_TARGETS = 'DELETE_TARGETS'
export const DELETE_TARGETS_SUCCESS = 'DELETE_TARGETS_SUCCESS'
export const DELETE_TARGETS_FAIL = 'DELETE_TARGETS_FAIL'
export const EMPTY_CHANGED_TARGET = 'EMPTY_CHANGED_TARGET'
