export const SELECT_VIEW_EDITOR = 'SELECT_VIEW_EDITOR'
export const LOAD_VIEW_EDITOR = 'LOAD_VIEW_EDITOR'
export const LOAD_VIEW_EDITOR_SUCCESS = 'LOAD_VIEW_EDITOR_SUCCESS'
export const LOAD_VIEW_EDITOR_FAIL = 'LOAD_VIEW_EDITOR_FAIL'
export const LOAD_ALL_VIEW_EDITOR = 'LOAD_ALL_VIEW_EDITOR'
export const LOAD_ALL_VIEW_EDITOR_SUCCESS = 'LOAD_ALL_VIEW_EDITOR_SUCCESS'
export const LOAD_ALL_VIEW_EDITOR_FAIL = 'LOAD_ALL_VIEW_EDITOR_FAIL'
export const SAVE_VIEW_EDITOR = 'SAVE_VIEW_EDITOR'
export const SAVE_VIEW_EDITOR_SUCCESS = 'SAVE_VIEW_EDITOR_SUCCESS'
export const SAVE_VIEW_EDITOR_FAIL = 'SAVE_VIEW_EDITOR_FAIL'
export const DELETE_VIEW_EDITOR = 'DELETE_VIEW_EDITOR'
export const DELETE_VIEW_EDITOR_SUCCESS = 'DELETE_VIEW_EDITOR_SUCCESS'
export const DELETE_VIEW_EDITOR_FAIL = 'DELETE_VIEW_EDITOR_FAIL'
