import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import category from './category'
import categoryDashboard from './category-dashboard'
import dashboard from './dashboard'
import filter from './filter'
import filterEditor from './filter-editor'
import filterGroup from './filter-group'
import impersonation from './impersonation'
import leftMenu from './left-menu'
import mapping from './mapping'
import dataFieldMapping from './data-field-mapping'
import standardizedValue from './standardized-values-mapping'
import me from './me'
import notification from './notification'
import organization from './organization'
import role from './role'
import schoolAdminDashboard from './school-admin-dashboard'
import target from './target'
import user from './user'
import view from './view'
import viewEditor from './view-editor'
import logs from './logs'
import contentMigration from './content-migration'
import {history} from './history'

const reducers = combineReducers({
  router: connectRouter(history),
  category,
  categoryDashboard,
  dashboard,
  filter,
  filterEditor,
  filterGroup,
  impersonation,
  leftMenu,
  mapping,
  dataFieldMapping,
  standardizedValue,
  me,
  notification,
  organization,
  role,
  schoolAdminDashboard,
  user,
  target,
  view,
  viewEditor,
  logs,
  contentMigration
})

export default reducers
