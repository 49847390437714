export const SELECT_DASHBOARD = 'SELECT_DASHBOARD'
export const EXPAND_DASHBOARD_HEADER = 'EXPAND_DASHBOARD_HEADER'
export const COLLAPSE_DASHBOARD_HEADER = 'COLLAPSE_DASHBOARD_HEADER'
export const OPEN_DRILL_DOWN = 'OPEN_DRILL_DOWN'
export const CLOSE_DRILL_DOWN = 'CLOSE_DRILL_DOWN'
export const OPEN_TABLE_DIALOG = 'OPEN_TABLE_DIALOG'
export const CLOSE_TABLE_DIALOG = 'CLOSE_TABLE_DIALOG'
export const OPEN_DATE_RANGE_PICKER = 'OPEN_DATE_RANGE_PICKER'
export const CLOSE_DATE_RANGE_PICKER = 'CLOSE_DATE_RANGE_PICKER'
export const CHANGE_DATE_RANGE = 'CHANGE_DATE_RANGE'
export const APPLY_DATE_RANGE = 'APPLY_DATE_RANGE'
