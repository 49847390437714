import * as types from '../constants/action-types/organization'
import axios from 'axios'

function initialState () {
  return {
    active: false,
    uuid: null,
    sid: '',
    name: '',
    loading: false,
    source: null,
    error: null
  }
}

function impersonateOrganization (state, { uuid, sid, name }) {
  return {
    ...state,
    uuid,
    sid,
    name,
    loading: true,
    source: axios.CancelToken.source()
  }
}

function impersonateOrganizationSuccess (state) {
  return {
    ...state,
    active: true,
    loading: false
  }
}

function impersonateOrganizationFail (state, { error }) {
  return {
    ...state,
    active: false,
    error: error,
    loading: false
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case types.IMPERSONATE_ORGANIZATION:
      return impersonateOrganization(state, action.payload)
    case types.IMPERSONATE_ORGANIZATION_SUCCESS:
      return impersonateOrganizationSuccess(state)
    case types.IMPERSONATE_ORGANIZATION_FAIL:
      return impersonateOrganizationFail(state, action.payload)
    case types.STOP_IMPERSONATING:
      return initialState()
    default:
      return state
  }
}
