import * as types from '../constants/action-types/filter-group'

export function getFilterGroups () {
  return { type: types.GET_FILTER_GROUPS }
}

export function getFilterGroupsSuccess (payload) {
  return { type: types.GET_FILTER_GROUPS_SUCCESS, payload }
}

export function getFilterGroupsFail (payload) {
  return { type: types.GET_FILTER_GROUPS_FAIL, payload }
}

export function createFilterGroup () {
  return { type: types.CREATE_FILTER_GROUP }
}

export function selectFilterGroupToEdit (payload) {
  return { type: types.SELECT_FILTER_GROUP_TO_EDIT, payload }
}

export function cancelFilterGroup () {
  return { type: types.CANCEL_FILTER_GROUP }
}

export function editFilterGroup (payload) {
  return { type: types.EDIT_FILTER_GROUP, payload }
}

export function addFilterGroupValue (payload) {
  return { type: types.ADD_FILTER_GROUP_VALUE, payload }
}

export function removeFilterGroupValue (payload) {
  return { type: types.REMOVE_FILTER_GROUP_VALUE, payload }
}

export function searchFilterGroups (payload) {
  return { type: types.SEARCH_FILTER_GROUPS, payload }
}

export function searchFilterGroupValues (payload) {
  return { type: types.SEARCH_FILTER_GROUP_VALUES, payload }
}

export function saveFilterGroup () {
  return { type: types.SAVE_FILTER_GROUP }
}

export function saveFilterGroupSuccess (payload) {
  return { type: types.SAVE_FILTER_GROUP_SUCCESS, payload }
}

export function saveFilterGroupFail (payload) {
  return { type: types.SAVE_FILTER_GROUP_FAIL, payload }
}

export function deleteFilterGroup () {
  return { type: types.DELETE_FILTER_GROUP }
}

export function deleteFilterGroupSuccess (payload) {
  return { type: types.DELETE_FILTER_GROUP_SUCCESS, payload }
}

export function deleteFilterGroupFail (payload) {
  return { type: types.DELETE_FILTER_GROUP_FAIL, payload }
}
