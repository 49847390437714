export const LOAD_FILTERS = 'LOAD_FILTERS'
export const LOAD_FILTERS_SUCCESS = 'LOAD_FILTERS_SUCCESS'
export const LOAD_FILTERS_FAIL = 'LOAD_FILTERS_FAIL'
export const LOAD_FILTER = 'LOAD_FILTER'
export const LOAD_FILTER_SUCCESS = 'LOAD_FILTER_SUCCESS'
export const LOAD_FILTER_FAIL = 'LOAD_FILTER_FAIL'
export const SHOW_FILTERS_PANEL = 'SHOW_FILTERS_PANEL'
export const HIDE_FILTERS_PANEL = 'HIDE_FILTERS_PANEL'
export const SELECT_FILTER = 'SELECT_FILTER'
export const ADD_SELECTED_VALUE = 'ADD_SELECTED_VALUE'
export const REMOVE_SELECTED_VALUE = 'REMOVE_SELECTED_VALUE'
export const SELECT_FILTER_GROUP = 'SELECT_FILTER_GROUP'
export const DESELECT_FILTER_GROUP = 'DESELECT_FILTER_GROUP'
export const SEARCH_FILTERS = 'SEARCH_FILTERS'
export const APPLY_FILTERS = 'APPLY_FILTERS'
export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export const REMOVE_ACTIVE_VALUE = 'REMOVE_ACTIVE_VALUE'
export const SET_FILTER_VALUE = 'SET_FILTER_VALUE'
export const SET_STANDALONE_FILTER_VALUES = 'SET_STANDALONE_FILTER_VALUES'
export const SAVE_ORGANIZATION_FILTER = 'SAVE_ORGANIZATION_FILTER'
export const SAVE_ORGANIZATION_FILTER_SUCCESS = 'SAVE_ORGANIZATION_FILTER_SUCCESS'
export const SAVE_ORGANIZATION_FILTER_FAIL = 'SAVE_ORGANIZATION_FILTER_FAIL'
export const TOGGLE_POPPER = 'TOGGLE_POPPER'

