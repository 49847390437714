export const GET_FILTER_GROUPS = 'GET_FILTER_GROUPS'
export const GET_FILTER_GROUPS_SUCCESS = 'GET_FILTER_GROUPS_SUCCESS'
export const GET_FILTER_GROUPS_FAIL = 'GET_FILTER_GROUPS_FAIL'
export const CREATE_FILTER_GROUP = 'CREATE_FILTER_GROUP'
export const SELECT_FILTER_GROUP_TO_EDIT = 'SELECT_FILTER_GROUP_TO_EDIT'
export const CANCEL_FILTER_GROUP = 'CANCEL_FILTER_GROUP'
export const EDIT_FILTER_GROUP = 'EDIT_FILTER_GROUP'
export const ADD_FILTER_GROUP_VALUE = 'ADD_FILTER_GROUP_VALUE'
export const REMOVE_FILTER_GROUP_VALUE = 'REMOVE_FILTER_GROUP_VALUE'
export const SEARCH_FILTER_GROUPS = 'SEARCH_FILTER_GROUPS'
export const SEARCH_FILTER_GROUP_VALUES = 'SEARCH_FILTER_GROUP_VALUES'
export const SAVE_FILTER_GROUP = 'SAVE_FILTER_GROUP'
export const SAVE_FILTER_GROUP_SUCCESS = 'SAVE_FILTER_GROUP_SUCCESS'
export const SAVE_FILTER_GROUP_FAIL = 'SAVE_FILTER_GROUP_FAIL'
export const DELETE_FILTER_GROUP = 'DELETE_FILTER_GROUP'
export const DELETE_FILTER_GROUP_SUCCESS = 'DELETE_FILTER_GROUP_SUCCESS'
export const DELETE_FILTER_GROUP_FAIL = 'DELETE_FILTER_GROUP_FAIL'
