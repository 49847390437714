export const OPEN_STANDARDIZED_VALUE_EDITOR = 'OPEN_STANDARDIZED_VALUE_EDITOR'
export const CLOSE_STANDARDIZED_VALUE_EDITOR = 'CLOSE_STANDARDIZED_VALUE_EDITOR'
export const GET_STANDARDIZED_VALUE_MAPPING = 'GET_STANDARDIZED_VALUE_MAPPING'
export const GET_STANDARDIZED_VALUE_MAPPING_SUCCESS = 'GET_STANDARDIZED_VALUE_MAPPING_SUCCESS'
export const GET_STANDARDIZED_VALUE_MAPPING_FAIL = 'GET_STANDARDIZED_VALUE_MAPPING_FAIL'
export const SAVE_STANDARDIZED_VALUE_MAPPING = 'SAVE_STANDARDIZED_VALUE_MAPPING'
export const SAVE_STANDARDIZED_VALUE_MAPPING_SUCCESS = 'SAVE_STANDARDIZED_VALUE_MAPPING_SUCCESS'
export const SAVE_STANDARDIZED_VALUE_MAPPING_FAIL = 'SAVE_STANDARDIZED_VALUE_MAPPING_FAIL'
export const CHANGE_STANDARDIZED_VALUE_MAPPING = 'CHANGE_STANDARDIZED_VALUE_MAPPING'
export const REMOVE_STANDARDIZED_VALUE = 'REMOVE_STANDARDIZED_VALUE'
export const ADD_STANDARDIZED_VALUE = 'ADD_STANDARDIZED_VALUE'
export const IS_CHANGED_VALUE = 'IS_CHANGED_VALUE'
