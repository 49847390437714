export const GET_FILTERS = 'GET_FILTERS'
export const GET_FILTERS_SUCCESS = 'GET_FILTERS_SUCCESS'
export const GET_FILTER = 'GET_FILTER'
export const GET_FILTER_SUCCESS = 'GET_FILTER_SUCCESS'
export const ADD_FILTER = 'ADD_FILTER'
export const ADD_FILTER_SUCCESS = 'ADD_FILTER_SUCCESS'
export const EDIT_FILTER = 'EDIT_FILTER'
export const EDIT_FILTER_SUCCESS = 'EDIT_FILTER_SUCCESS'
export const DELETE_FILTER = 'DELETE_FILTER'
export const DELETE_FILTER_SUCCESS = 'DELETE_FILTER_SUCCESS'
export const FILTERS_FAIL = 'FILTERS_FAIL'
export const SET_FILTER_DETAIL = 'SET_FILTER_DETAIL'
