export const GET_PERMISSIONS = 'GET_PERMISSIONS'
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS'
export const GET_PERMISSIONS_FAIL = 'GET_PERMISSIONS_FAIL'
export const CREATE_ROLE = 'CREATE_ROLE'
export const SELECT_ROLE = 'SELECT_ROLE'
export const SAVE_ROLE = 'SAVE_ROLE'
export const SAVE_ROLE_SUCCESS = 'SAVE_ROLE_SUCCESS'
export const SAVE_ROLE_FAIL = 'SAVE_ROLE_FAIL'
export const DELETE_ROLE = 'DELETE_ROLE'
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS'
export const DELETE_ROLE_FAIL = 'DELETE_ROLE_FAIL'
export const EDIT_ROLE = 'EDIT_ROLE'
export const CHANGE_PERMISSION = 'CHANGE_PERMISSION'
