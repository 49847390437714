import * as types from '../constants/action-types/notification'

const initialState = () => ({
  all: []
})

const addNotification = (state, { payload }) => ({
  ...state,
  all: [...state.all, payload]
})

const delNotification = (state) => ({
  ...state,
  all: state.all.slice(1)
})

const notification = (state = initialState(), action) => {
  switch (action.type) {
    case types.ADD_NOTIFICATION:
      return addNotification(state, action)
    case types.DEL_NOTIFICATION:
      return delNotification(state)
    default:
      return state
  }
}

export default notification
