export const getTermFilter = (state) => {
  return state.filter.filters.find(filter => filter.variable_name.startsWith('term'))
}

export const getActivePanelFilters = (state) => {
  return state.filter.filters.reduce((filters, filter) => {
    if (filter.uuid in state.filter.activeFilters && !filter.standalone) {
      filters.push({
        uuid: filter.uuid,
        name: filter.name,
        variable_name: filter.variable_name,
        filter_group_name: state.filterGroup && state.filterGroup.filterGroups.find(el => el.filter_uuid === filter.uuid) !== undefined ? state.filterGroup.filterGroups.find(el => el.filter_uuid === filter.uuid).name : '',
        selectedValues: state.filter.activeFilters[filter.uuid]
      })
    }
    return filters
  }, [])
}
