import api from '../handlers/api'
import * as actions from '../actions/school-admin-dashboard'
import { addNotification } from '../actions/notification'
import { getCurrentOrganizationUUID } from '../selectors/organization'
import { MessageType } from '../constants'

export function loadAdminDashboards () {
  return async (dispatch, getState) => {
    try {
      dispatch(actions.loadAdminDashboards())
      const state = getState()
      const organizationUUID = getCurrentOrganizationUUID(state)
      const [dashboards, categories] = await Promise.all([
        api.get(`/organizations/${organizationUUID}/dashboards`),
        api.get('/categories')
      ])
      dispatch(actions.loadAdminDashboardsSuccess({
        dashboards: dashboards.data,
        categories: categories.data.categories
      }))
    } catch (error) {
      dispatch(actions.loadAdminDashboardsFail(error.message))
      dispatch(addNotification({
        message: 'Failed to get the dashboards list',
        type: MessageType.ERROR
      }))
    }
  }
}

export function saveModifiedDashboards (categoryUUID) {
  return async (dispatch, getState) => {
    try {
      dispatch(actions.saveModifiedDashboards())
      const state = getState()
      const organizationUUID = getCurrentOrganizationUUID(state)
      const dashboards = state.schoolAdminDashboard.allDashboards[categoryUUID]

      const promises = dashboards.map((dashboard) => {
        return api.patch(`/organizations/${organizationUUID}/dashboards/${dashboard.id}`, dashboard)
      })

      await Promise.all(promises)
      dispatch(actions.saveModifiedDashboardsSuccess())
      dispatch(addNotification({
        message: 'All dashboards were saved',
        type: MessageType.ERROR
      }))
    } catch (error) {
      dispatch(actions.saveModifiedDashboardsFail(error.message))
      dispatch(addNotification({
        message: 'Failed to save the dashboards',
        type: MessageType.ERROR
      }))
    }
  }
}
