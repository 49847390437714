import Breadcrumbs from './Breadcrumbs'
import { UserType } from '../../constants'

export const PublicPaths = {
  MAINTENANCE: {
    path: '/maintenance'
  },
  NOT_ALLOWED: {
    path: '/not-allowed'
  }
}

export const PrivatePaths = {
  HOME: {
    path: '/'
  },
  DASHBOARDS: {
    path: '/dashboard',
    userTypes: [UserType.SCHOOL_ADMIN, UserType.NORMAL_USER]
  },
  DASHBOARD: {
    path: '/dashboard/:dashboardId',
    userTypes: [UserType.SCHOOL_ADMIN, UserType.NORMAL_USER]
  },
  ORGANIZATION_DASHBOARDS: {
    path: '/:organizationId/dashboard',
    userTypes: [UserType.TARGETX_ADMIN]
  },
  ORGANIZATION_DASHBOARD: {
    path: '/:organizationId/dashboard/:dashboardId',
    userTypes: [UserType.TARGETX_ADMIN]
  }
}

export const SchoolAdminPaths = {
  SCHOOL_ADMIN_DASHBOARDS: {
    path: '/admin-dashboard/:categoryUUID',
    userTypes: [UserType.SCHOOL_ADMIN],
    text: 'Dashboards',
    icon: 'dashboard',
    breadcrumbs: Breadcrumbs.SCHOOL_ADMIN_DASHBOARDS
  },
  MAPPING: {
    path: '/mapping/:mapperUUID',
    userTypes: [UserType.SCHOOL_ADMIN],
    text: 'Mapping',
    icon: 'map',
    breadcrumbs: Breadcrumbs.MAPPING
  },
  MAPPINGDATAFIELD: {
    path: '/mappings/data-field',
    userTypes: [UserType.SCHOOL_ADMIN],
    text: 'MAPPING',
    icon: 'map'
  },
  MAPPINGSTANDARDVALUES: {
    path: '/mappings/standardized-values',
    userTypes: [UserType.SCHOOL_ADMIN],
    text: 'MAPPING',
    icon: 'map'
  },
  TARGETS: {
    path: '/targets',
    userTypes: [UserType.SCHOOL_ADMIN],
    text: 'Targets',
    icon: 'trophy'
  },
  USERS: {
    path: '/users',
    userTypes: [UserType.SCHOOL_ADMIN],
    text: 'Users',
    icon: 'users'
  },
  ROLES: {
    path: '/roles',
    userTypes: [UserType.SCHOOL_ADMIN],
    text: 'Roles & Permissions',
    icon: 'key'
  },
  ROLE: {
    path: '/roles/:roleUUID',
    userTypes: [UserType.SCHOOL_ADMIN],
    text: 'Roles & Permissions',
    breadcrumbs: Breadcrumbs.ROLE
  },
  FILTER_GROUPS: {
    path: '/filter-groups',
    userTypes: [UserType.SCHOOL_ADMIN],
    text: 'Filter Groups',
    icon: 'filter'
  },
  SCHOOL_CONFIG: {
    path: '/school-config',
    userTypes: [UserType.SCHOOL_ADMIN],
    text: 'School Configurations',
    icon: 'graduationcap'
  },
  ORGANIZATION_ADMIN_DASHBOARDS: {
    path: '/:organizationId/admin-dashboard/:categoryUUID',
    userTypes: [UserType.TARGETX_ADMIN],
    text: 'Dashboards',
    icon: 'dashboard',
    breadcrumbs: Breadcrumbs.SCHOOL_ADMIN_DASHBOARDS
  },
  ORGANIZATION_MAPPING: {
    path: '/:organizationId/mapping/:mapperUUID',
    userTypes: [UserType.TARGETX_ADMIN],
    text: 'Mapping',
    icon: 'map',
    breadcrumbs: Breadcrumbs.MAPPING
  },
  ORGANIZATION_MAPPINGDATAFIELD: {
    path: '/:organizationId/mappings/data-field',
    userTypes: [UserType.TARGETX_ADMIN],
    text: 'MAPPING',
    icon: 'map'
  },
  ORGANIZATION_MAPPINGSTANDARDVALUES: {
    path: '/:organizationId/mappings/standardized-values',
    userTypes: [UserType.TARGETX_ADMIN],
    text: 'MAPPING',
    icon: 'map'
  },
  ORGANIZATION_TARGETS: {
    path: '/:organizationId/targets',
    userTypes: [UserType.TARGETX_ADMIN],
    text: 'Targets',
    icon: 'trophy'
  },
  ORGANIZATION_USERS: {
    path: '/:organizationId/users',
    userTypes: [UserType.TARGETX_ADMIN],
    text: 'Users',
    icon: 'users'
  },
  ORGANIZATION_ROLES: {
    path: '/:organizationId/roles',
    userTypes: [UserType.TARGETX_ADMIN],
    text: 'Roles & Permissions',
    icon: 'key'
  },
  ORGANIZATION_ROLE: {
    path: '/:organizationId/roles/:roleUUID',
    userTypes: [UserType.TARGETX_ADMIN],
    text: 'Roles & Permissions',
    breadcrumbs: Breadcrumbs.ROLE
  },
  ORGANIZATION_FILTER_GROUPS: {
    path: '/:organizationId/filter-groups',
    userTypes: [UserType.TARGETX_ADMIN],
    text: 'Filter Groups',
    icon: 'filter'
  },
  ORGANIZATION_SCHOOL_CONFIG: {
    path: '/:organizationId/school-config',
    userTypes: [UserType.TARGETX_ADMIN],
    text: 'School Configurations',
    icon: 'graduationcap'
  }
}

export const TargetXAdminPaths = {
  ORGANIZATIONS: {
    path: '/organization',
    userTypes: [UserType.TARGETX_ADMIN],
    text: 'Organizations',
    icon: 'graduationcap'
  },
  ORGANIZATION: {
    path: '/organization/:organizationId',
    userTypes: [UserType.TARGETX_ADMIN],
    breadcrumbs: Breadcrumbs.ORGANIZATION
  },
  VIEWS: {
    path: '/view',
    userTypes: [UserType.TARGETX_ADMIN],
    text: 'View Editor',
    icon: 'edit'
  },
  VIEW: {
    path: '/view/:viewId',
    userTypes: [UserType.TARGETX_ADMIN],
    breadcrumbs: Breadcrumbs.VIEW
  },
  VIEW_PREVIEW: {
    path: '/view/:viewId/preview',
    userTypes: [UserType.TARGETX_ADMIN],
    breadcrumbs: Breadcrumbs.VIEW_PREVIEW
  },
  FILTERS: {
    path: '/filter',
    userTypes: [UserType.TARGETX_ADMIN],
    text: 'Filter Editor',
    icon: 'filter'
  },
  FILTER: {
    path: '/filter/:filterUUID',
    userTypes: [UserType.TARGETX_ADMIN],
    breadcrumbs: Breadcrumbs.FILTER
  },
  CATEGORIES: {
    path: '/category',
    userTypes: [UserType.TARGETX_ADMIN],
    text: 'Categories',
    icon: 'layout-text'
  },
  CATEGORY_DASHBOARDS: {
    path: '/category-dashboard',
    userTypes: [UserType.TARGETX_ADMIN],
    text: 'Dashboards',
    icon: 'dashboard'
  },
  LOGGER_DASHBOARDS: {
    path: '/logs',
    userTypes: [UserType.TARGETX_ADMIN],
    text: 'Logs',
    icon: 'duplicate'
  },
  CONTENT_MIGRATION: {
    path: '/content-migration',
    userTypes: [UserType.TARGETX_ADMIN],
    text: 'Content Migration',
    icon: 'duplicate'
  }
}

export const HelpPaths = {
  HELP: {
    path: '/help'
  },
  INTRO: {
    path: '/help#intro',
    text: 'Intro',
    icon: 'bullhorn'
  },
  FAQ: {
    path: '/help#faq',
    text: 'FAQ',
    icon: 'faq'
  },
  HELP_FILTERS: {
    path: '/help#filters',
    text: 'Filters',
    icon: 'bookmark-solid'
  },
  HELP_FILTER_GROUPS: {
    path: '/help#filter-groups',
    text: 'Filter Groups',
    icon: 'bookmark-solid'
  },
  HELP_MAPPINGS: {
    path: '/help#mappings',
    text: 'Mappings',
    icon: 'bookmark-solid'
  },
  HELP_TARGETS: {
    path: '/help#targets',
    text: 'Targets',
    icon: 'bookmark-solid'
  },
  HELP_USERS: {
    path: '/help#users',
    text: 'Users',
    icon: 'bookmark-solid'
  },
  HELP_ROLES_PERMISSIONS: {
    path: '/help#roles-permissions',
    text: 'Roles & Permissions',
    icon: 'bookmark-solid'
  },
  HELP_DATA_MODEL: {
    path: '/help#data-model',
    text: 'Data Model',
    icon: 'bookmark-solid'
  },
  HELP_EXPLORE_MORE: {
    path: '/help#explore-more',
    text: 'Explore More',
    icon: 'bookmark-solid'
  },
  HELP_FILTERS_EQUITY_ACADEMIC_DASHBOARD: {
    path: '/help#filters-equity-academic-programs-dashboard',
    text: 'Filters Equity Academic Programs Dashboard',
    icon: 'bookmark-solid'
  }
}

export const getPathParams = (path, urlName) => {
  const pathTokens = path.split('/')
  const pathnameTokens = (urlName || window.location.pathname).split('/')
  const isEqual = pathTokens.every((pathToken, i) => pathToken.startsWith(':') || pathToken === pathnameTokens[i])
  if (!isEqual) {
    return null
  } else {
    return pathTokens.reduce((params, token, i) => {
      return token.startsWith(':')
        ? {
          ...params,
          [token.slice(1)]: pathnameTokens[i]
        }
        : params
    }, {})
  }
}

export const setPathParams = (path, params, queryParams) => {
  if (!path) return ''
  let buildPath = path

  Object.keys(params).forEach((paramKey) => {
    buildPath = buildPath.replace(`:${paramKey}`, params[paramKey])
  })

  if (queryParams) {
    const search = Object.keys(queryParams)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
      .join('&')
    buildPath = `${buildPath}?${search}`
  }

  return buildPath
}

export const isSubPath = (path, currentPath) => currentPath?.endsWith(path)

export const getActivePath = (paths, currentPath) => {
  if (currentPath) {
    return Object.values(paths).find(({ path }) => {
      const segments = path.split('/').filter(segment => segment !== '')
      const currentSegments = currentPath.split('/').filter(segment => segment !== '')

      if (segments.length !== currentSegments.length) return false

      return segments.every((segment, index) => {
        if (segment.startsWith(':')) return true
        return segment === currentSegments[index]
      })
    })
  }
}

export default {
  ...PublicPaths,
  ...PrivatePaths,
  ...SchoolAdminPaths,
  ...TargetXAdminPaths,
  ...HelpPaths
}
