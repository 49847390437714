import api from '../handlers/api'
import * as actions from '../actions/me'
import { addNotification } from '../actions/notification'
import { loadAdminDashboards } from './school-admin-dashboards'
import { loadFilters } from './filter'
import { getFilterGroups } from './filter-group'
import { MessageType, UserType } from '../constants'

export function loadMe () {
  return async (dispatch, getState) => {
    try {
      dispatch(actions.loadMe())
      const response = await api.get('/me')

      dispatch(actions.loadMeSuccess(response.data))
      localStorage.setItem('txOrgShortName', response.data.organization.sid)

      if (response.data.in_maintenance) return

      const state = getState()

      if (state.me.userType !== UserType.TARGETX_ADMIN || state.impersonation.active) {
        dispatch(loadFilters())
        dispatch(getFilterGroups())
      }

      if (state.me.userType === UserType.SCHOOL_ADMIN || state.impersonation.active) {
        dispatch(loadAdminDashboards())
      }

      if (state.me.userType !== UserType.TARGETX_ADMIN) {
        window.mixpanel.init(response.data)
      }

      window.mixpanel.track('Page load')
      window.mixpanel.track('Page view', { URL: window.location.pathname })
    } catch (error) {
      dispatch(actions.loadMeFail(error.message))
    }
  }
}

export function logOut () {
  return async (dispatch) => {
    try {
      await api.get('/logout')
      window.mixpanel.track('Logout')
      api.redirectToLogin(window.location.origin)
    } catch (error) {
      dispatch(addNotification({
        message: 'Failed to log out',
        type: MessageType.ERROR
      }))
    }
  }
}
