import { createSelector } from 'reselect'
import moment from 'moment'

export const getSelectedDashboard = ({ dashboard }) => {
  return dashboard.dashboards.find(d => d.id === dashboard.selectedDashboardId)
}

export const getCategoryDashboardName = createSelector(getSelectedDashboard, (selectedDashboard) => {
  return selectedDashboard ? `${selectedDashboard.category} - ${selectedDashboard.name}` : null
})

export const getLoadingViews = ({ view }) => {
  const views = Object.values(view)
  if (!views.length) return true
  return views.some(view => view.loading)
}

export const getLoadingFilters = ({ filter }) => {
  return filter.loading || filter.filters
    .filter(filter => filter.standalone)
    .some(filter => filter.loading)
}

export const getLoadingDashboards = ({ dashboard }) => {
  return dashboard.dashboards.length === 0
}

export const getStartDate = ({ dashboard }) => {
  return moment.utc(dashboard.dateRange.startDate).startOf('day').valueOf()
}

export const getEndDate = ({ dashboard }) => {
  return moment.utc(dashboard.dateRange.endDate).endOf('day').milliseconds(0).valueOf()
}
