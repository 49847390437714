import * as types from '../constants/action-types/filter-group'

export const newFilterGroup = {
  name: '',
  filter_uuid: null,
  values: []
}

function initialState () {
  return {
    filterGroups: [],
    changedFilterGroup: newFilterGroup,
    loading: false,
    saving: false,
    deleting: false,
    editorOpen: false,
    isNew: false,
    searchText: '',
    editorSearchText: '',
    error: null
  }
}

function getFilterGroups (state) {
  return {
    ...state,
    loading: true
  }
}

function getFilterGroupsSuccess (state, { payload: filterGroups }) {
  return {
    ...state,
    filterGroups,
    loading: false
  }
}

function getFilterGroupsFail (state, { payload: error }) {
  return {
    ...state,
    error,
    loading: false
  }
}

function createFilterGroup (state) {
  return {
    ...state,
    changedFilterGroup: { ...newFilterGroup },
    editorOpen: true,
    editorSearchText: '',
    isNew: true
  }
}

function selectFilterGroupToEdit (state, { payload: filterGroup }) {
  return {
    ...state,
    changedFilterGroup: filterGroup,
    editorOpen: true,
    editorSearchText: '',
    isNew: false
  }
}

function cancelFilterGroup (state) {
  return {
    ...state,
    editorOpen: false
  }
}

function editFilterGroup (state, { payload: { field, value } }) {
  return {
    ...state,
    changedFilterGroup: {
      ...state.changedFilterGroup,
      [field]: value,
      values: field === 'name' ? state.changedFilterGroup.values : []
    }
  }
}

function addFilterGroupValue (state, { payload: value }) {
  return {
    ...state,
    changedFilterGroup: {
      ...state.changedFilterGroup,
      values: [...state.changedFilterGroup.values, value]
    }
  }
}

function removeFilterGroupValue (state, { payload }) {
  return {
    ...state,
    changedFilterGroup: {
      ...state.changedFilterGroup,
      values: state.changedFilterGroup.values.filter(value => value !== payload)
    }
  }
}

function searchFilterGroups (state, { payload: searchText }) {
  return {
    ...state,
    searchText
  }
}

function searchFilterGroupValues (state, { payload: searchText }) {
  return {
    ...state,
    editorSearchText: searchText
  }
}

function saveFilterGroup (state) {
  return {
    ...state,
    saving: true
  }
}

function saveFilterGroupSuccess (state, { payload: { filterGroup, isNew, date, user } }) {
  return {
    ...state,
    saving: false,
    editorOpen: false,
    filterGroups: isNew
      ? [...state.filterGroups, { ...filterGroup, creation_date: date, created_by: user }]
      : state.filterGroups.map(fg => {
        if (fg.uuid !== filterGroup.uuid) return fg
        return { ...filterGroup, last_edit_date: date, last_edit_by: user }
      }),
    createdUUID: isNew ? filterGroup.uuid : null
  }
}

function saveFilterGroupFail (state, { payload: error }) {
  return {
    ...state,
    error,
    saving: false
  }
}

function deleteFilterGroup (state) {
  return {
    ...state,
    deleting: true
  }
}

function deleteFilterGroupSuccess (state, { payload: filterGroupUUID }) {
  return {
    ...state,
    deleting: false,
    filterGroups: state.filterGroups.filter(filterGroup => filterGroup.uuid !== filterGroupUUID)
  }
}

function deleteFilterGroupFail (state, { payload: error }) {
  return {
    ...state,
    error,
    deleting: false
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case types.GET_FILTER_GROUPS:
      return getFilterGroups(state)
    case types.GET_FILTER_GROUPS_SUCCESS:
      return getFilterGroupsSuccess(state, action)
    case types.GET_FILTER_GROUPS_FAIL:
      return getFilterGroupsFail(state, action)
    case types.CREATE_FILTER_GROUP:
      return createFilterGroup(state)
    case types.SELECT_FILTER_GROUP_TO_EDIT:
      return selectFilterGroupToEdit(state, action)
    case types.CANCEL_FILTER_GROUP:
      return cancelFilterGroup(state)
    case types.EDIT_FILTER_GROUP:
      return editFilterGroup(state, action)
    case types.ADD_FILTER_GROUP_VALUE:
      return addFilterGroupValue(state, action)
    case types.REMOVE_FILTER_GROUP_VALUE:
      return removeFilterGroupValue(state, action)
    case types.SEARCH_FILTER_GROUPS:
      return searchFilterGroups(state, action)
    case types.SEARCH_FILTER_GROUP_VALUES:
      return searchFilterGroupValues(state, action)
    case types.SAVE_FILTER_GROUP:
      return saveFilterGroup(state)
    case types.SAVE_FILTER_GROUP_SUCCESS:
      return saveFilterGroupSuccess(state, action)
    case types.SAVE_FILTER_GROUP_FAIL:
      return saveFilterGroupFail(state, action)
    case types.DELETE_FILTER_GROUP:
      return deleteFilterGroup(state)
    case types.DELETE_FILTER_GROUP_SUCCESS:
      return deleteFilterGroupSuccess(state, action)
    case types.DELETE_FILTER_GROUP_FAIL:
      return deleteFilterGroupFail(state, action)
    default:
      return state
  }
}
