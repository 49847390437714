import { createSelector } from 'reselect'
import { sortBy } from 'lodash'

export const getOrganizationsList = (state) => {
  const organizations = Object.values(state.organization.all)
  return sortBy(organizations, 'name')
}

export const getDataSourceList = (state) => {
  return state.organization.dataSources
}

export const getInsightsOrganizations = createSelector(getOrganizationsList, (organizations) => {
  return organizations.filter(organization => organization.created)
})

export const getSelectedOrganizationName = (state) => {
  const { changedOrganization } = state.organization
  const organization = changedOrganization ? state.organization.all[changedOrganization.sid] : null

  if (!changedOrganization) {
    return null
  } else if (organization && organization.created) {
    return organization.name
  } else {
    return 'New'
  }
}

export const getCurrentOrganizationUUID = (state) => {
  return state.impersonation.uuid || state.me.organizationUUID
}
