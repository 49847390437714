export const getSelectedRoleName = (state) => {
  const { changedRole } = state.role
  const role = changedRole ? state.me.roles.find(role => role.uuid === changedRole.uuid) : null

  if (!changedRole) {
    return null
  } else if (role) {
    return role.name
  } else {
    return 'New'
  }
}
