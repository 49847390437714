export default {
  root: {
    padding: '4px 10px 3px',
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    '&$disabled': {
      backgroundColor: '#DDE3ED',
      borderColor: 'rgba(0, 0, 0, 0.26)',
      color: 'rgba(0, 0, 0, 0.38)'
    }
  },
  underline: {
    '&:before, &:after': {
      display: 'none'
    }
  }
}
