import { createSelector } from 'reselect'
import { getCurrentPath } from './router'
import Paths, { getActivePath } from '../components/routes/Paths'

export const getSelectedCategory = ({ schoolAdminDashboard, router }) => {
  const currentPath = getCurrentPath({ router })
  const activePath = getActivePath(Paths, currentPath)
  if ((activePath.path === Paths.SCHOOL_ADMIN_DASHBOARDS.path) || (activePath.path === Paths.ORGANIZATION_ADMIN_DASHBOARDS.path)) {
    const selectedCategory = currentPath.split('/').pop()
    return schoolAdminDashboard.categories.find(category => category.uuid === selectedCategory) || {}
  }
  return {}
}

export const getSelectedCategoryName = createSelector(getSelectedCategory, (category) => category.name)
