import * as types from '../constants/action-types/left-menu'
import { GO_TO } from '../constants/action-types/path'

const initialState = () => ({
  menuOpen: window.innerWidth >= 1280,
  settingsOpen: false,
  helpOpen: false,
  expandedCategory: null,
  isContentMigrationVisible: false
})

const openLeftMenu = (state) => ({
  ...state,
  menuOpen: true
})

const closeLeftMenu = (state) => ({
  ...state,
  menuOpen: false,
  settingsOpen: false,
  helpOpen: false
})

const openSettingsMenu = (state) => ({
  ...state,
  settingsOpen: true
})

const closeSettingsMenu = (state) => ({
  ...state,
  settingsOpen: false
})

const openHelpMenu = (state) => ({
  ...state,
  helpOpen: true
})

const closeHelpMenu = (state) => ({
  ...state,
  helpOpen: false
})

const toggleLeftMenuCategory = (state, { payload }) => ({
  ...state,
  expandedCategory: state.expandedCategory === payload ? null : payload
})

function checkAccessforContentMigration (state) {
  return {
    ...state,
    isContentMigrationVisible: false
  }
}

function checkAccessforContentMigrationSuccess (state, { payload }) {
  const { is_system_super_admin: isSuperAdmin } = payload
  return {
    ...state,
    isContentMigrationVisible: isSuperAdmin,
    error: null
  }
}

function checkAccessforContentMigrationFail (state, { payload }) {
  return {
    ...state,
    isContentMigrationVisible: false,
    error: payload
  }
}

const leftMenu = (state = initialState(), action) => {
  switch (action.type) {
    case types.OPEN_LEFT_MENU:
      return openLeftMenu(state)
    case types.CLOSE_LEFT_MENU:
      return closeLeftMenu(state)
    case types.OPEN_SETTINGS_MENU:
      return openSettingsMenu(state)
    case types.CLOSE_SETTINGS_MENU:
      return closeSettingsMenu(state)
    case types.OPEN_HELP_MENU:
      return openHelpMenu(state)
    case types.CLOSE_HELP_MENU:
      return closeHelpMenu(state)
    case types.TOGGLE_LEFT_MENU_CATEGORY:
      return toggleLeftMenuCategory(state, action)
    case types.CHECK_CONTENT_MIGRATION:
      return checkAccessforContentMigration(state)
    case types.CHECK_CONTENT_MIGRATION_SUCCESS:
      return checkAccessforContentMigrationSuccess(state, action)
    case types.CHECK_CONTENT_MIGRATION_FAIL:
      return checkAccessforContentMigrationFail(state, action)
    case GO_TO:
      return window.innerWidth < 1280 ? closeLeftMenu(state) : state
    default:
      return state
  }
}

export default leftMenu
